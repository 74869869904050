/*.industrySlider-row {
    padding: 6rem 0;
}*/
.industrySlider-card {
    background: #fff;
    border: 1px solid #ededed;
    border-radius: 25px;
    box-shadow: 0 0 20px rgb(0 0 0 / 5%);
    padding: 2rem 2rem;
    display:flex !important;
    justify-content:center;
    align-items:center;
    cursor:pointer;
}

.industrySlider-slider .slick-list .slick-track .slick-slide {
    padding: 0px 35px;
}

    .industrySlider-slider .slick-list .slick-track .slick-slide img {
        width: 100%;
        height:100px;
        object-fit:contain;
    }
.industrySlider-slider .slick-prev:hover, .industrySlider-slider .slick-prev:focus, .industrySlider-slider .slick-next:hover, .industrySlider-slider .slick-next:focus, .industrySlider-slider .slick-next, .industrySlider-slider .slick-prev {
    background: var(--mnr-red);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    z-index: 1;
}
    .industrySlider-slider .slick-next{
        right:15px;
    }
.industrySlider-slider .slick-prev {
    left: 15px;
}

    .industrySlider-slider .slick-prev:before {
        background-image: url('../../../img/left.png');
        background-size: 24px 24px;
        display: inline-block;
        width: 24px;
        height: 24px;
        content: "";
        opacity: 1;
    }

    .industrySlider-slider .slick-next:before {
        background-image: url('../../../img/next.png');
        background-size: 24px 24px;
        display: inline-block;
        width: 24px;
        height: 24px;
        content: "";
        opacity: 1;
    }
.industrySlider-section{
    padding-bottom: 5rem;
    
}

.slider-initials{
    height:100px;
    width:100px;
}
/*@media only screen and (min-width: 320px) and (max-width: 767px) {
    .industrySlider-slider .slick-list .slick-track .slick-slide img {
        height: 140px;
    }
}*/