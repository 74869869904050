
.general_info_input {
    font-size: 16px;
    color: #B1B0B0;
    font-weight: 600;
    position: relative;
}

    .general_info_input .form-control::placeholder, .general_info_input .form-select::placeholder {
        color: #B1B0B0;
    }

    .general_info_input .form-control, .general_info_input .form-select, .input-group-dropdown-1 {
        border: 1px solid hsla(0, 1%, 69%, .25);
        border-radius: 0;
        box-shadow: 0 0 10px rgb(0 0 0 / 2%);
        box-sizing: border-box;
        height: 40px;
        padding: 0.375rem 0.75rem;
        margin-bottom: 3rem;
    }

        .general_info_input .form-select option {
            color: #B1B0B0;
        }

        .general_info_input .form-select:focus {
            background-color: #fff !important;
            border-color: #86B7FE !important;
            box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%) !important;
            color: #B1B0B0 !important;
            outline: 0 !important;
        }

        .general_info_input .form-control:focus {
            background-color: #fff !important;
            border-color: #86B7FE !important;
            box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%) !important;
            color: #212529 !important;
            outline: 0 !important;
        }

    .general_info_input label span {
        color: red;
        padding: 0px 4px 10px 4px;
    }

.general-address {
    height: unset !important;
}

.general-icon-password-visible {
    position: absolute;
    top: 35px;
    right: 15px;
    font-size: 22px;
    color: #9b9797;
    cursor: pointer;
}

.option-dropdown {
    color: #b1b0b0;
}

.input-group-dropdown-1 {
    padding: 0 10px;
    max-width: 32%;
    margin-right: 4px;
}

    .input-group-dropdown-1:focus {
        background-color: #fff !important;
        border-color: #86B7FE !important;
        color: #B1B0B0 !important;
        outline: 0 !important;
        box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%) !important;
    }

.date-picker-signup .rmdp-container {
    font-size: 16px;
    color: #B1B0B0;
    font-weight: 600;
    position: relative;
    display: block !important;
    margin-bottom: 3rem;
}

    .date-picker-signup .rmdp-container input {
        background: #fff;
        border: 1px solid hsla(0, 1%, 69%, .25);
        border-radius: 0;
        box-shadow: 0 0 10px rgb(0 0 0 / 2%);
        box-sizing: border-box;
        height: 40px;
        padding: 0.375rem 0.75rem;
        width: 100%;
        padding-left: 15px;
    }
.side-line-signup{
    position:relative;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
    .date-picker-signup .rmdp-container {
        margin-bottom: 1.5rem;
    }
}