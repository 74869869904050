
.general_info_input {
    font-size: 16px;
    font-weight: 600;
    position:relative;
}
    .general_info_input .form-control::placeholder, .general_info_input .form-select::placeholder{
        color: #B1B0B0;
    }
    .general_info_input .form-control, .general_info_input .form-select {
        border: 1px solid hsla(0, 1%, 69%, .25);
        border-radius: 0;
        box-shadow: 0 0 10px rgb(0 0 0 / 2%);
        box-sizing: border-box;
        height: 40px;
        padding: 0.375rem 0.75rem;
        margin-bottom: 3rem;
    }
    .general_info_input .form-select {
        background-image: url("../../../img/chevron-down.svg") !important;
        background-size:15px;
    }
        .general_info_input .form-select option {
            color: #B1B0B0;
        }
        .general_info_input .form-select:focus {
            background-color: #fff !important;
            border-color: #86B7FE !important;
            box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%) !important;
            color: #B1B0B0 !important;
            outline: 0 !important;
        }
        .general_info_input .form-control:focus {
            background-color: #fff !important;
            border-color: #86B7FE !important;
            box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%) !important;
            color: #212529 !important;
            outline: 0 !important;
        }

    .general_info_input label span {
        color: red;
        padding: 0px 4px 10px 4px;
    }

.general-address {
    height:unset !important;
}
.general-icon-password-visible {
    position: absolute;
    top: 34px;
    right: 15px;
    font-size: 22px;
    color: #9b9797;
    cursor: pointer;
}

.option-dropdown {
    color: #b1b0b0;
}
.general-info-width{
    width:49%
}

#dobPicker::-webkit-calendar-picker-indicator {
    padding-left: 350px;
}

.arrow-down-signup {
    width: 1px;
    background-color: hsl(0, 0%, 80%);
    margin-bottom: 8px;
    margin-top: 8px;
    box-sizing: border-box;
    position: absolute;
    right: 34px;
    top: -6%;
    font-size: 20px;
}


@media only screen and (min-width: 320px) and (max-width: 767px) {
    .new-phone-mobile {
        margin-bottom: 0 !important;
    }
    .general-icon-password-visible {
        top: 39px;
        font-size: 18px;
    }
}