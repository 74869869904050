.copyright {
    background: #222;
    color: #fff;
    font-size: 13px;
    border-top: none;
    padding: 5px 0;
    font-family: Open Sans,sans-serif;
    width: 100%;
    position:fixed;
    bottom: 0;
    z-index: 1;
}

.loading-footer{
    z-index:unset !important;
}

.copyright p {
    margin: 15px 0px;
    font-weight: 300;
    line-height: 1.5rem;
}

    .copyright a {
        color: #72c02c !important;
    }

@media only screen and (max-width:481px) {
    .copyright {
        width: 100%;
        bottom: 0;
        position: relative;
        z-index: 995;
    }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
    .copyright p {
        margin: 15px;
    }
}
