.citizenship_input {
    font-size: 16px;
    color: #B1B0B0;
    font-weight: 600;
    position: relative;
}

    .citizenship_input .form-control::placeholder, .citizenship_input .form-select::placeholder {
        color: #B1B0B0;
    }

    .citizenship_input .form-control {
        background: #fff;
        border: 1px solid hsla(0, 1%, 69%, .25);
        border-radius: 0;
        box-shadow: 0 0 10px rgb(0 0 0 / 2%);
        box-sizing: border-box;
        height: 40px;
        margin-bottom: 3rem;
        padding: 0.375rem 0.75rem;
    }

        .citizenship_input .form-control:focus {
            background-color: #fff !important;
            border-color: #86B7FE !important;
            box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%) !important;
            color: #212529 !important;
            outline: 0 !important;
        }

    .citizenship_input label span {
        color: red;
        padding: 0px 4px 10px 4px;
    }

    .citizenship_input .image-preview img {
        border: 1px dashed #b8292f;
        border-radius: 10px;
        height: 250px;
        padding: 5px;
        width: 100%;
    }

    .citizenship_input .upload_button {
        position: relative;
    }

        .citizenship_input .upload_button label {
            cursor: pointer;
            display: block;
            background: #be9f4a;
            border-radius: 5px;
            color: #fff;
            display: flex;
            font-size: 14px;
            font-weight: 400;
            justify-content: center;
            margin-top: 10px;
            padding: 10px 20px;
            text-align: center;
            width: 100%;
        }

            .citizenship_input .upload_button label span {
                padding: 0;
            }

                .citizenship_input .upload_button label span img {
                    height: 20px;
                    margin-bottom: 1px;
                    margin-right: 10px;
                }

.label-input-simple {
    color: #b1b0b0;
}

.input-file-qualification input::-webkit-file-upload-button {
    height: 40px !important;
    color: #B1B0B0 !important;
}

.candidate-passport {
    background: #fff;
    border: 1px solid hsla(0, 1%, 69%, .25);
    border-radius: 0;
    box-shadow: 0 0 10px rgb(0 0 0 / 2%);
    box-sizing: border-box;
    height: 40px;
    margin-bottom: 3rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #000;
    font-weight: 400;
    padding: 12px;
}

.label-section-after-submit-uploaders {
    width: 69%;
    display: block;
    float: left;
    margin-bottom: 23px;
}

.input-label-show {
    height: 40px;
    border-radius: 0px;
    border: 1px solid hsla(0, 1%, 69%, .25);
}

.passport-upload-second-tab {
    margin-bottom: 25px !important;
}

.download-button {
    width: 30%;
}

.main-section-label-download {
    width: 100%;
    display: flex;
    float: left;
    justify-content: space-between;
}

.download-button-labels {
    width: 30%;
    position: relative;
}

    .download-button-labels button {
        width: 100%;
        height: 40px;
        border: none;
        background: #faf9f9;
        color: #72c02c;
        text-transform: uppercase;
        font-size: .8rem;
        text-align: left;
        padding-left: 23px;
        border: 1px solid #72c02c;
        border-radius: 30px;
    }

.icons-download-uploaders {
    position: absolute;
    right: 22px;
    top: 11px;
    color: #72c02c;
    font-size: 17px;
}


@media only screen and (min-width: 320px) and (max-width: 767px) {

    .main-section-label-download {
        width: 100%;
        flex-direction: column;
    }

    .label-section-after-submit-uploaders {
        width: 100%;
        margin-bottom: 12px;
    }

    .download-button-labels {
        width: 50%;
        margin-bottom: 10px;
    }
    .nfoos-mobile .form-control{
        margin-bottom:0 !important;
    }
    .previous-btn-signup{
        font-size:.7rem !important;
    }
    .citizenship_input .form-control{
        margin-bottom:1.5rem;
    }
    .citizenship_input{
        font-size:15px;
    }
        .citizenship_input .form-control {
        font-size:15px
        }
    .icons-download-uploaders {
        right: 45px;
    }
    .download-button-labels button {
        font-size: .7rem;
        height: 36px;
        padding-left: 40px;
        width:95%;
    }
    .passport-upload-second-tab {
        margin-bottom: 0px !important;
    }
        }
