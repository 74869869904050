.hiring-section {
    background-color: var(--mnr-light-grey);
}
.hiring-row {
    padding: 3rem 0;
}
.hiring-second-row {
    position: relative;
    row-gap: 3rem;
    padding-bottom: 4rem;
}
    .hiring-head {
        font-size: 2.5rem;
        line-height: 3rem;
        /*padding-bottom: 1.5rem;
        padding-top: 1.5rem;
        margin-bottom: 1rem;*/
        font-weight:600;
    }
.col-heading {
    margin-bottom: 1.5rem;
    font-size: .8rem;
    font-weight: 600;
    color: var(--mnr-red);
    line-height: 2rem;
    text-transform: uppercase;
    text-align: center;
}
.col-img {
    margin-bottom: 1.5rem;
    width:146px;
}
.col-para {
    text-align: center;
    font-size: .8rem;
    font-weight: 300;
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
}
.arrow-img {
    height: 45px;
}
.arrow-col {
    display: flex;
    justify-content: center;
    align-items: center;
}
.arrRotate {
    transform: rotate(180deg);
}
.hiring-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.arrow-col-position {
    position: absolute;
    right: -180px;
    transform: rotate(450deg);
    top: 47%;
}
.extra-arrow-hiring{
    display:none !important;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
    .arrow-col, .arrow-col-position {
        display: none;
    }
    .hiring-second-row{
        row-gap:0;
    }
    .extra-arrow-hiring{
        display:flex !important;
        margin-bottom:20px
    }
    .hiring-section-inner-sct{
        flex-direction: column-reverse !important;
    }
}