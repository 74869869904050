.featuredSection {
    background-color: var(--mnr-light-grey);
    position:relative;
    z-index:-1;
    padding-bottom:7rem !important;
}
#featuredCandidateScroller .owl-item {
    padding: 50px;
    opacity: .2;
    -webkit-transform: scale(.8);
    transform: scale(.8);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

    #featuredCandidateScroller .owl-item.active.center {
        opacity: 1;
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }

    #featuredCandidateScroller .owl-item .featuredImage {
        border-radius: 50%;
        width: 170px;
        height: 170px;
        object-fit: cover;
    }

    #featuredCandidateScroller .owl-item .img {
        max-width: 200px;
        margin: 25px auto 15px;
    }
#featuredCandidateScroller .feturedContainer h4 {
    font-size: 1rem;
    line-height: 2rem;
    color: var(--mnr-red);
}

#featuredCandidateScroller .feturedContainer p {
    font-size: .8rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 1.6rem;
    line-height: 24px;
}
    #featuredCandidateScroller .feturedContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
#featuredCandidateScroller .owl-dots .owl-dot.active span,
#featuredCandidateScroller .owl-dots .owl-dot:hover span {
    background: var(--mnr-red);
    transform: translate3d(0px, -50%, 0px) scale(0.7);
}

#featuredCandidateScroller .owl-dots {
    display: inline-block;
    width: 100%;
    text-align: center;
}

    #featuredCandidateScroller .owl-dots .owl-dot {
        display: inline-block;
    }

        #featuredCandidateScroller .owl-dots .owl-dot span {
            background: var(--mnr-red);
            display: inline-block;
            height: 15px;
            width: 15px;
            margin: 0 2px 5px;
            transform: translate3d(0px, -50%, 0px) scale(0.3);
            transform-origin: 50% 50% 0;
            transition: all 250ms ease-out 0s;
        }
@media only screen and (min-width: 320px) and (max-width: 767px) {
    .featuredSection {
        padding-bottom: 11rem !important;
    }
}