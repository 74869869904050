.guidance-section {
    background-color: var(--mnr-light-grey);
}

.guidance-row {
    padding: 3rem 0;
}
.guidance-head {
    font-size: 2.5rem;
    line-height: 3rem;
    margin-top: 1rem;
    padding-top: 1.5rem;
    /*margin-bottom: 1rem;*/
    /*letter-spacing: 1px;*/
    font-weight:600;
}
.guidance-img-checked {
    font-size: 1.5rem;
    line-height: 2.5rem;
    font-weight: 600;
    color:var(--mnr-red);
}
    .guidance-img-checked img {
        /*margin-right: 10px;*/
        height: 29px;
    }

.guidance-data {
    font-weight: 300;
    line-height: 1.5rem;
    font-size: 17px;
    margin-bottom:3rem;
}

.size-set-image-outer{
    width: 500px;

}
.size-set-image-outer img{}
.size-set-image-outer img{}