.banner-section {
    background-color: var(--mnr-light-grey);
    padding-top:75px
}

.home-banner-row {
    padding: 3rem 0;
}

.banner-positions {
    font-weight: 400;
    line-height: .5rem;
    font-size: 1.25rem;
}
.home-banner-inner-col{
    margin-top:1rem;
}
.banner-btn {
    border: 0;
    border-radius: 0;
    color: #fff;
    font-size: .8rem;
    letter-spacing: .1rem;
    opacity: 1;
    padding: 10px 12px;
    text-transform: uppercase;
    transition: all .3s ease;
    background-color: var(--mnr-gold);
    border-radius: 50px;
    text-decoration: none;
}

.banner-head {
    color: #746d5d;
    font-size: 3rem;
    font-weight: 300;
    line-height: 4rem;
    margin-bottom: 3rem; 
}

.banner-head span{
    color:var(--mnr-red);
}
.banner-search-btn {
    background-color: var(--mnr-red);
    border: none;
    border-radius: 50px;
    color: #fff;
    font-size: 15px !important;
    font-weight: 600;
    margin: 0 5px;
    padding: 15px 25px !important;
    max-width: 500px;
    margin-bottom: 3rem !important;
    height: 3.5rem;
    width:100%;
}

    .banner-search-btn:hover {
        color: #fff;
        opacity: .7;
        transition: all .3s ease;
    }
.banner-search-input {
    background-color: #fff;
    border: 1px solid #eeeded;
    border-radius: 50px;
    box-shadow: 0 0 30px rgb(0 0 0 / 5%);
    max-width: 500px;
    width:100%;
    height:3.5rem;
    padding:0 3rem;
    margin:0 5px
}
    .banner-search-input:focus-visible{
        outline:none;
    }
    .banner-search {
        position: relative;
    }
.search-icon-banner {
    position: absolute;
    font-size: 21px;
    top: 18px;
    left: 22px;
    color: var(--mnr-dark-grey)
}

@media only screen and (min-width: 320px) and (max-width: 767px){
    .banner-positions{
        line-height:2rem;
    }
}