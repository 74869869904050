.company-covered-row {
    padding: 3.5rem 0;
    margin-bottom:2rem;
}

.company-covered-head {
    font-size: 2.5rem;
    line-height: 3rem;
    font-weight: 600;
}

.company-inner-head {
    color: var(--mnr-red);
    font-size: 1.5rem;
    line-height: 2.5rem;
    font-weight: 600;
}
.company-list {
    background: var(--mnr-light-grey);
    display: flex;
    align-items: center;
    border-radius: 1rem;
    margin-bottom: 1rem;
    padding: 1rem;
    cursor:pointer;
}

.company-img {
    height:75px;
    width:75px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.company-list-head {
    font-size: 1.5rem;
    font-weight:300;
    margin-left:1rem;
}

