#similarJobsIntro {
    text-align: left;
    padding: 2rem 0;
}

#similarJobsIntro h3 {
    color: #000;
}

#similarJobsList .applied {
    padding: 20px 0 0 20px;
}

#similarJobsList .applied p {
    margin-top: 1rem;
    margin-bottom: 0 !important;
    color: #000000;
    font-weight: 400;
    font-size: .8rem;
}

#similarJobsList .card-text strong {
    padding-left: 25px;
}
