#stakeHolderIntro {
    padding-top: 4.5rem;
}

#stakeHolderIntro .card {
    background: transparent;
    border: none;
    box-shadow: none;
    padding: 3rem 0;
    margin-bottom: 0 !important;
}

    #stakeHolderIntro .card-body {
        padding: 0;
    }

    #stakeHolderIntro .card-title {
        font-size: 26px !important;
        font-weight:600 !important;
        line-height:2.5rem !important;
    }

        #stakeHolderIntro .card-title:after {
            content: "";
        }

    #stakeHolderIntro h3 {
        color: #000;
        margin-bottom: .5rem;
        font-size: 22px;
        padding-top: 20px;
    }

    #stakeHolderIntro .card-img {
        width: 100%;
        float: left;
        margin-left: 0;
        height: 125px;
        object-fit: contain;
    }

#stakeHolderIntro .card-text, #stakeHolderIntro .card-text span, #stakeHolderIntro .card-text a {
    color: var(--mnr-red) !important;
    text-decoration: none;
    font-weight: 400;
    font-size:17px;
}

    #stakeHolderIntro .contacts-divider {
        margin: 0 15px;
    }

.stakeholder-content-wrapper .side h3, .open-positions h3 {
    font-size: 18px !important;
}

.open-positions h3 {
    margin-bottom: 0 !important;
}

.stakeholder-content-wrapper .side h4 {
    text-transform: uppercase;
    font-weight: 400 !important;
    font-size: 12px !important;
    margin-bottom: 0 !important;
}

.stakeholder-content-wrapper .side p {
    margin-bottom: 0 !important;
}

/*.side .job-detail:before {
    content: url(http://localhost:44439/static/media/job-deadline.2c6a535….svg);
    display: inline-block;
    line-height: 1rem !important;
    position: absolute;
    left: 2rem;
    background-color: var(--mnr-red);
    padding: 0.6rem .9rem;
    border-radius: 5px;
    height: 50px;
    width: 50px;
    display: flex !important;
    justify-content: center;
    align-items: center;
}*/

/*#jobDetails .side .job-detail {
    padding-left: 4rem;
}*/

/*.side .side-bar .job-detail:nth-child(1):before {
    content: url("../../img/stakeholder-location.svg") !important;
}

.side .side-bar .job-detail:nth-child(2):before {
    content: url("../../img/stakeholder-company.svg") !important;
}

.side .side-bar .job-detail:nth-child(3):before {
    content: url("../../img/stakeholder-industry.svg") !important;
}

.side .side-bar .job-detail:nth-child(4):before {
    content: url("../../img/stakeholder-revenue.svg") !important;
}

.side .side-bar .job-detail:nth-child(5):before {
    content: url("../../img/stakeholder-salary.svg") !important;
}*/

.side .map-wrapper {
    padding: 0 !important;
}

.stakeholder-header-icon img {
    margin-right: 6px;
    margin-top: -2px;
}

.stakeholder-header-icon .img1 {
    height: 13px;
}

.stakeholder-header-icon .img2 {
    height: 20px;
}

.stakeholder-header-icon svg {
    font-size: 14px;
    margin-right: 2px;
}

.stakeholder-detail {
    display: flex;
    align-items: center;
    padding-left: 0rem !important;
    margin-bottom: 3rem !important;
}
.sidebar-stake-icon {
    background-color: var(--mnr-red);
    padding: 0.6rem 0.9rem;
    border-radius: 5px;
    height: 50px;
    width: 50px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    font-size: 23px;
    color: #fff;
    margin-right: 15px;
}
    .sidebar-stake-data{
        padding-top:10px;
    }

    .stake-long-name {
        line-height: 2.5rem;
        font-weight: 600;
        display: block !important;
    }

.stakeholder-jobs-data{
    font-weight:400 !important;
    margin-top:1rem !important;
    line-height:1.5rem;
}

.stakeholder-aboutus {
    font-size: 13px !important;
    font-weight: 300;
    line-height: 1.5rem;
    text-indent:-1.2rem;
    padding-left:27px;
}

.stakeholder-aboutus-head {
    line-height: 2.5rem;
    font-weight: 600;
    font-size:22px
}

.headquarters-stakeholder {
    font-size: 15px !important;
    font-weight: 400 !important;
}

.stakeholder-aboutus:before {
    content: url("../../img/checked.svg");
    display: inline-block;
    margin-right: .5rem;
    vertical-align: middle;
    transform: scale(.6);
    line-height: 1rem;
}
.stakeholder-bold b{
    font-weight:500
}
.maps-stackholder{
    height: 500px !important;
}
.stake-new-margin{
    margin-bottom:140px
}

.side .map-wrapper .gm-style .gmnoprint .gm-style-mtc ul li {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    text-indent: unset !important;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
    .stakeholder-links-resp {
        display: block;
        margin-bottom:6px
    }
    .stakeholder-aboutus {
        font-size: 12px !important;
        font-weight: 400;
        line-height: 1.5rem
    }
    .stakeholder-aboutus-head {
        font-size: 20px;
    }
    .stake-long-name{
        line-height:unset;
    }
    .sidebar-stake-icon {
        height: 45px;
        width: 45px;
        font-size: 20px;
    }
    }