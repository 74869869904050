.general_info_input {
    font-size: 16px;
    color: #b1b0b0;
    font-weight: 600;
}
.citizenship_input .form-control::placeholder, .citizenship_input .form-select::placeholder {
    color: #B1B0B0;
}
.general_info_input .form-control {
    border: 1px solid hsla(0, 1%, 69%, .25);
    border-radius: 0;
    box-shadow: 0 0 10px rgb(0 0 0 / 2%);
    box-sizing: border-box;
    height: 40px;
    padding: 0.375rem 0.75rem;
    margin-bottom: 3rem;
}
 .multi-selectjob .select__control {
    background: #fff;
    border: 1px solid hsla(0, 1%, 69%, .25);
    border-radius: 0;
    box-shadow: 0 0 10px rgb(0 0 0 / 2%);
    box-sizing: border-box;
    min-height: 40px;
    margin-bottom: 3rem;
}
.general_info_input .form-control:focus {
    background-color: #fff !important;
    border-color: #86b7fe !important;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%) !important;
    color: #B1B0B0 !important;
    outline: 0 !important;
}
    .general_info_input .form-control option {
        color: #B1B0B0;
    }
    .general_info_input label span {
        color: red;
        padding: 0px 4px 10px 4px;
    }

.option-dropdown:disabled {
    color: #b1b0b0;
}

.input-file-qualification input {
    height: unset !important;
    color: #B1B0B0 !important;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
    .cv-mob{
        margin-bottom:1rem;
    }
    .general_info_input .form-control, .general_info_input .form-select, .input-group-dropdown-1{
        margin-bottom: 1.5rem;
        font-size:15px;
    }
    .general_info_input .input-group {
        margin-bottom: 0 !important;
    }
    .multi-selectjob .select__control {
        margin-bottom: 1.5rem;
    }
}