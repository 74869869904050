/* Headings font */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

/* Body font */
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
 

/* Variables */
/*:root {
    --mnr-gold: #C7AB61;
    --mnr-red: #DB333B;
    --mnr-green: #72C02C;
    --mnr-yellow: #FFBC2D;
    --mnr-light-grey: #FAF9F9;
    --mnr-dark-grey: #B1B0B0;
}*/

/* General styles */

/*body {
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
}

h1, h2, h3, h4, h5 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
}

h1 {
    font-size: 3rem;
    line-height: 3.5rem;
}

h2 {
    font-size: 2.5rem;
    line-height: 3rem;
}

h3 {
    font-size: 1.5rem;
    line-height: 2.5rem;
    color: var(--mnr-red);
}

h4 {
    font-size: 1rem;
    line-height: 2rem;
    color: var(--mnr-red);
}


p {
    line-height: 1.5rem;
    margin-bottom: 3rem;
    font-weight: 300;
}

a {
    color: var(--mnr-gold) !important;
    opacity: 1;
    transition: all ease 0.3s;
}
*/
a:hover {
    opacity: .7;
}

.container-fluid {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
}

.border1 {
    border: 1px solid black !important;
}

.btn {
    border-radius: 0;
    border: 0;
    color: white;
    font-size: .8rem;
    text-transform: uppercase;
    letter-spacing: .1rem;
    padding: 1rem;
    opacity: 1;
    transition: all ease 0.3s;
}

    .btn:hover {
        color: white;
        opacity: .7;
    }

.btn-primary, .btn-primary:hover {
    background-color: var(--mnr-green);
}

.btn-secondary, .btn-secondary:hover {
    background-color: var(--mnr-gold);
}

.btn-tertiary, .btn-tertiary:hover {
    background-color: var(--mnr-yellow);
}

.alternative-section {
    background-color: var(--mnr-light-grey);
}
.Toastify {
    z-index: 999999;
    position: relative;
}
input[type=password]::-webkit-textfield-decoration-container {
    visibility: hidden;
    pointer-events: none;
}

/************************************** HOME PAGE STYLES **************************************/
/* Hero section */
#hero span {
    color: var(--mnr-red);
}

#hero .lead {
    line-height: 2rem;
}

/* Industries & Companies section */

.industries {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    list-style: none;
    padding: 0;
}

    .industries li {
        background-color: var(--mnr-light-grey);
        padding: 1rem;
        border-radius: 1rem;
        margin-bottom: 1rem;
        font-size: 1.5rem;
    }

        .industries li:before {
            width: 75px;
            height: 75px;
            border-radius: 50%;
            background-color: white;
            display: inline-block;
            margin-right: 1rem;
            display: inline-flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            vertical-align: middle;
        }

.oil-gas li:nth-child(1):before {
    content: url("../img/exploration.svg");
}

.oil-gas li:nth-child(2):before {
    content: url("../img/equipment.svg");
}

.oil-gas li:nth-child(3):before {
    content: url("../img/pipeline.svg");
}

.power li:nth-child(1):before {
    content: url("../img/renewable.svg");
}

.power li:nth-child(2):before {
    content: url("../img/conventional.svg");
}

.power li:nth-child(3):before {
    content: url("../img/transmission.svg");
}

/* Guidance / rules section */

#guidance h3:before {
    content: url("../img/checked.svg");
    display: inline-block;
    margin-right: .5rem;
    vertical-align: middle;
    transform: scale(.9);
    line-height: 1rem;
}

/* Hiring */
#hiring {
    text-align: center;
}

    #hiring h4 {
        font-size: .8rem;
        text-transform: uppercase;
    }

    #hiring p {
        font-size: .8rem;
    }

    #hiring h4, #hiring img {
        margin-bottom: 1.5rem;
    }

.arrow-right-down {
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
}

.arrow-left-up {
    -webkit-transform: scale(-1, -1);
    transform: scale(-1,-1);
}

.arrow-left-down {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

/* Only for Mobile */
.extra-arrow {
    margin-bottom: -3rem;
}

/* Important Information */


#importantInfo ul li, #jobDetails ul li {
    border: none;
    text-indent: -1.3em;
    padding-left: 3em;
    margin-bottom: 1.5rem;
}

    #importantInfo ul li:before, #jobDetails .job-details-role li:before {
        content: url("../img/checked.svg");
        display: inline-block;
        margin-right: .5rem;
        vertical-align: middle;
        transform: scale(.8);
        line-height: 1rem;
    }

/* Testimonials */

#testimonials {
    text-align: center;
}

    #testimonials p {
        font-size: .8rem;
        font-weight: 700;
    }

.save-continue-submit{
    border-radius: 30px !important;
}
.application-submit {
    border-radius: 30px;
    padding: 15px 40px;
}
/************************************** JOBS PAGE STYLES **************************************/
/* Intro */
#jobsIntro, #similarJobsIntro, #applicationIntro {
    text-align: center;
    padding: 5rem 0;
}
.app-page {
    padding: 10rem 0 5rem 0 !important;
}
.app-form {
    padding: 0px 0px 70px 0px !important;
}


    #jobsIntro h1, #applicationIntro h1 {
        font-weight: 300;
        margin-bottom: 1.5rem;
    }

    #jobsIntro p, #applicationIntro p {
        color: var(--mnr-dark-grey);
    }

/* Search widget */

#jobSearchWidget {
    text-align: center;
    padding: 5rem 0;
}

    #jobSearchWidget .job-search {
        padding: 2rem;
        position: relative;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.07);
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    }

    /*#job-search-widget .field-wrapper {
    position: relative;
}*/

    #jobSearchWidget .field-wrapper .mb-3 {
        margin: 0 !important;
    }

    #jobSearchWidget label, #jobSearchWidget .job-search input, #jobSearchWidget .job-search input::placeholder, #jobSearchWidget .job-search select {
        border: none;
        font-size: 1.1rem;
        color: var(--mnr-dark-grey);
    }

    #jobSearchWidget .job-search input {
        border-radius: 0;
        margin-left: 1rem;
    }

    #jobSearchWidget .job-search .search-field-icon:before, #jobSearchWidget .job-search .cities-field-icon:before, #jobSearchWidget .job-search .job-family-field-icon:before {
        content: url("../img/magnify.svg");
        transform: scale(.8) translateY(-50%);
        display: block;
        position: absolute;
        top: 50%;
    }

    #jobSearchWidget .job-search .cities-field-icon:before {
        content: url("../img/map-location.svg");
    }

    #jobSearchWidget .job-search .job-family-field-icon:before {
        content: url("../img/briefcase.svg");
    }

    #jobSearchWidget .field-wrapper:after {
        content: "";
        display: inline-block;
        height: 70px;
        width: 1px;
        background-color: var(--mnr-light-grey);
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    #jobSearchWidget .field-wrapper:last-child:after {
        content: none;
    }

.form-select {
    background-image: url("../img/grey-caret.svg");
    background-size: 7px;
    padding-left: 30px;
}

#jobFamilyField {
    padding-left: 40px;
}

#jobSearchWidget .job-search textarea:focus,
#jobSearchWidget .job-search textarea.form-control:focus,
#jobSearchWidget .job-search input.form-control:focus,
#jobSearchWidget .job-search input[type=text]:focus,
#jobSearchWidget .job-search input[type=password]:focus,
#jobSearchWidget .job-search input[type=email]:focus,
#jobSearchWidget .job-search input[type=number]:focus,
#jobSearchWidget .job-search [type=text].form-control:focus,
#jobSearchWidget .job-search [type=password].form-control:focus,
#jobSearchWidget .job-search [type=email].form-control:focus,
#jobSearchWidget .job-search [type=tel].form-control:focus,
#jobSearchWidget .job-search [contenteditable].form-control:focus
#jobSearchWidget .job-search select:focus,
#jobSearchWidget .job-search #jobCitiesField,
#jobSearchWidget .job-search #jobFamilyField {
    box-shadow: none !important;
}

#jobSearchWidget .advanced-search {
    font-size: 1.1rem;
    background-color: none;
    color: #000000;
    margin-top: 15px;
    float: left;
}

    #jobSearchWidget .advanced-search:after {
        content: url("../img/black-caret.svg");
        margin-top: -10px;
        margin-left: 15px;
    }

#jobSearchWidget .find-jobs-btn {
    min-width: 120px;
}

    #jobSearchWidget .find-jobs-btn, #jobSearchWidget .find-jobs-btn:hover {
        background-color: var(--mnr-green);
    }

#jobSearchWidget .dropdown {
    width: 100%;
    padding-left: 10px;
    position: unset;
}

#jobSearchWidget .mega-menu {
    width: 100% !important;
    position: absolute !important;
    inset: 0px 0px auto auto !important;
    transform: translate3d(0, 130px, 0) !important;
    padding: 2rem;
    border-radius: 0;
    border: 1px solid rgba(0, 0, 0, 0.03);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
}

    #jobSearchWidget .mega-menu label {
        margin-bottom: 1.5rem;
    }

    #jobSearchWidget .mega-menu input {
        background: var(--mnr-light-grey);
        margin-left: 0;
        height: 58px;
        margin-bottom: 2rem;
    }

    #jobSearchWidget .mega-menu .form-select, #jobsFilter .form-select {
        height: 58px;
        background-color: var(--mnr-light-grey);
        background-image: url("../img/grey-caret.svg");
        background-repeat: no-repeat;
        background-position: right 0.75rem center;
        background-size: 7px;
        border: none;
        color: var(--mnr-dark-grey);
        margin-bottom: 2rem;
        border-radius: 0;
    }

#jobSearchWidget input[type="date"]::-webkit-calendar-picker-indicator {
    background: url("../img/date.svg") no-repeat;
    width: 20px;
    height: 20px;
    margin-top: 5px;
    margin-right: -5px;
}

/*#job-search-widget .mega-menu:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
    position: absolute;
    top: -10px;
    right: 20%;
}
*/


/* Jobs filter */

.jobs-search-filter {
    background-color: #fff;
    border: 1px solid var(--mnr-red);
    border-radius: 10px;
    color: var(--mnr-red) !important;
    font-size: 13px !important;
    font-weight: 600;
    /*padding: 5px 30px !important;*/
    display: inline-block;
    margin: 5px 10px 0px 0px;
    width:46%;
}

.jobs-search-filter > span {
    text-align:left;
    float: left;
    color: black;
    font-size: 13px !important;
    font-weight: 600;
    margin: 7px;
}

    .jobs-search-filter > a {
        text-align: right;
        float: right;
        color: var(--mnr-red) !important;
        font-size: 20px !important;
        font-weight: 600;
        cursor: pointer;
        margin: 0 10px;
    }

.clearallfilter {
    cursor: pointer;
    margin-left: 20px;
    color: var(--mnr-red) !important;
    text-decoration:none;
}
/*.signup-button {
    color: #fff !important;
    background-color: #72C02C !important;
    margin: 8px 4px;
    padding: 8px 20px!important;
    font-size: 13px !important;
}*/
.signup-button a {
    color: var(--mnr-red) !important;
    text-decoration: none;
}

#jobsFilter {
    padding: 2rem 0 1rem 0;
}


/* Jobs */
#jobsList .card, #jobDetailsIntro .card, #similarJobsList .card {
    border: 1px solid rgba(0, 0, 0, 0.07);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.02);
    border-radius: 15px;
    padding: 1.5rem;
    margin-bottom: 2rem;
}

#jobsList .odd, #jobDetailsIntro .odd, #similarJobsList .odd {
    background: #FFFFFF;
}

#jobsList .even, #jobDetailsIntro .even, #similarJobsList .even {
    background: var(--mnr-light-grey);
}

#jobsList .card h3, #similarJobsList .card h3 {
    color: #000000;
    font-size: 18px;
}

#jobsList .card-img, #similarJobsList .card-img {
    width: 10%;
    float: left;
    margin-right: 30px;
    height: 74px;
    object-fit: contain;
}

#jobsList .badge, #jobDetailsIntro .badge, #similarJobsList .badge {
    border-radius: 50px;
    font-size: 10px;
    font-weight: 400;
    padding: 5px 10px;
    margin: 0 5px;
}

#jobsList .green, #jobDetailsIntro .green, #similarJobsList .green {
    background: var(--mnr-green);
}

#jobsList .gold, #jobDetailsIntro .gold, #similarJobsList .gold {
    background: var(--mnr-gold);
}

#jobsList .featured, #jobDetailsIntro .featured, #similarJobsList .featured {
    color: var(--mnr-red);
    font-size: 12px;
    font-weight: 400;
    margin: 0 5px;
}

#jobsList .card-text, #jobDetailsIntro .card-text, #similarJobsList .card-text {
    font-size: 13px;
    color: var(--mnr-dark-grey);
}

    #jobsList .card-text span, #jobDetailsIntro .card-text span, #similarJobsList .card-text span {
        margin-right: 20px;
    }

    #jobsList .card-text .job-family, #jobDetailsIntro .job-family, #similarJobsList .card-text .job-family {
        margin-left: 20px;
    }

    #jobsList .card-text .job-salary, #jobDetailsIntro .job-salary, #similarJobsList .card-text .job-salary {
        margin-right: 0;
    }

#jobDetailsIntro .card .card-body .card-text i, #jobDetailsIntro .card .card-body .card-text svg {
    color: var(--mnr-dark-grey);
    font-size: 12px;
    margin: 0px 8px 0px 0px;
}

#jobsList .card-text span:before, #similarJobsList .card-text span:before {
    transform: scale(.7);
    position: absolute;
    margin-top: -4px;
    margin-left: -25px;
}

#jobsList .card-text .job-family:before, #jobDetailsIntro .job-family:before, #similarJobsList .job-family:before {
    /*content: url("../img/briefcase-1.svg");*/
}

#jobsList .card-text .job-location:before, #jobDetailsIntro .job-location:before, #similarJobsList .job-location:before {
    /*content: url("../img/location.svg");*/
}

#jobsList .card-text .job-date-added:before, #jobDetailsIntro .job-date-added:before, #similarJobsList .job-date-added:before {
    /*content: url("../img/clock.svg");*/
}

#jobsList .card-text .job-salary:before, #jobDetailsIntro .job-salary:before, #similarJobsList .job-salary:before {
    /*content: url("../img/money.svg");*/
}

#jobsList .card-text .job-links, #jobDetailsIntro .card-text .job-links, #similarJobsList .card-text .job-links {
    float: right;
    margin-top: 0 !important;
}

    #jobsList .card-text .job-links span, #jobDetailsIntro .card-text .job-links span, #similarJobsList .card-text .job-links span {
        margin: 0;
    }

        #jobsList .card-text .job-links span a, #jobDetailsIntro .card-text .job-links span a, #similarJobsList .card-text .job-links span a {
            color: var(--mnr-green) !important;
            text-decoration: none;
            font-size: 14px;
        }

        #jobsList .card-text .job-links span button, #similarJobsList .card-text .job-links span button {
            color: var(--mnr-green) !important;
            font-size: 14px;
            border: none;
            background: transparent;
        }

            #jobsList .card-text .job-links span button:after, #similarJobsList .card-text .job-links span button:after {
                content: url("../img/link-arrow.svg");
                margin-left: 15px;
            }



        #jobsList .card-text .job-links span a:after, #jobDetailsIntro .card-text .job-links span a, #similarJobsList .card-text .job-links span a:after {
            content: url("../img/link-arrow.svg");
            margin-left: 15px;
        }

#similarJobsList .card-text, #jobsList .card-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.similar-jobs-badges-resp{
    display:inline;
}

.similar-jobs {
    padding-bottom: 7rem !important;
}
.pagination {
    display: flex;
    justify-content: center;
}
    .pagination .page-item .page-link {
        border: 0;
    }
        .pagination .page-item .page-link:not(.disabled, .active) {
            color: black;
        }
    .pagination .page-item .active, .pagination .page-item .active:hover {
        background-color: green;
        color: white;
        z-index: unset;
        margin-left:3px;
        margin-right:3px;
        border-radius:50%;
        min-width:34px;
        height:34px;
    }
   
    .pagination .page-item .page-link:focus {
        /*background: none;*/
        /*border: none;*/
        box-shadow: unset;
    }
    .pagination .page-item .next-prev:focus {
        z-index: unset;
        background-color: unset;
    }
        /************************************** JOB DETAILS PAGE STYLES **************************************/
        #jobDetailsIntro {
            /*margin-top: -15px;*/
            padding-top: 5rem;
        }
    #jobDetailsIntro .card {
        background: transparent;
        border: none;
        box-shadow: none;
        margin-bottom: 0 !important;
        padding: 3rem 0;
    }

    #jobDetailsIntro .card-body {
        padding: 0;
    }
        #jobDetailsIntro .card-body .img-col {
            display: flex;
            align-items: center;
        }
#jobDetailsIntro .card .cardImg {
    width: 100%;
    object-fit: contain;
    border-top-left-radius:initial;
    border-top-right-radius:initial;
    border-bottom-right-radius:initial;
    border-bottom-left-radius:initial
}
    #jobDetailsIntro .card h3 {
        line-height: 2.5rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
    }

    #jobDetailsIntro .card-body .badge-wrapper {
        margin: 1rem 0;
    }

        #jobDetailsIntro .card-body .badge-wrapper .badge {
            margin-left: 0;
        }
#jobDetailsIntro .card-text span:before {
        transform: scale(.7);
        position: absolute;
        margin-top: -2px;
        margin-left: -25px;
    }

    #jobDetailsIntro .card-title {
        font-size: 26px !important;
    }

    /*#jobDetailsIntro .card-title:after {
            content: url("../img/check-2.svg");
            margin-left: 10px;
            line-height: 2;
            position: absolute;
        }*/

    #jobDetailsIntro .apply {
        font-weight: 600;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        color: #FFF !important;
        padding: 15px 25px;
        font-size: 15px !important;
        text-transform: uppercase;
        letter-spacing: .1rem;
        border-radius: 30px;
        background-color: var(--mnr-red) !important;
    }
.apply-now-view-dtls:active {
    background: #72C02C !important;
    border-color: #72C02C !important;
}
#jobDetails .other-para {
    margin-bottom: 3rem;
}

    #jobDetails .other-para h3 {
        color: #000;
        margin-bottom: 1.5rem;
        font-size: 22px;
    }

#jobDetails ul li {
    list-style: none;
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 1rem;
    padding-left: 28px;
}

#jobDetails ul .resp {
    padding-left: 14px;
}

#jobDetails .job-details-role li:before {
    content: url("../img/checked.svg");
    display: inline-block;
    margin-right: .5rem;
    vertical-align: middle;
    transform: scale(.6);
    line-height: 1rem;
}

#jobDetails .side-bar {
    background: var(--mnr-light-grey);
    padding: 2rem;
    position: relative;
}
    #jobDetails .employer-company{
        margin-bottom:4rem !important;
    }
    #jobDetails .side-bar .logo-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    #jobDetails .side-bar h4 {
        margin-bottom: 0;
        font-size: 15px;
        color: #000;
        font-weight: 600;
    }

    #jobDetails .side-bar li {
        font-size: 13px;
        padding-left: 20px;
        margin-bottom: 10px;
        margin-top: 10px;
        list-style-position: outside;
    }

    #jobDetails .side-bar p {
        font-size: 13px;
        padding-left: 0px;
        margin-bottom: 10px;
        margin-top: 10px;
        list-style-position: outside;
    }

    #jobDetails .side-bar .job-detail {
        padding-left: 2rem;
        margin-bottom: 1.5rem;
    }

        #jobDetails .side-bar .job-detail i {
            color: #d8c388 !important;
            line-height: 2.1rem;
            position: absolute;
            left: 32px;
            font-size: 18px;
            margin-top: -4px;
        }

        #jobDetails .side-bar .job-detail:before {
            /*content: url("../img/job-details-icons/job-deadline.svg");*/
            display: inline-block;
            line-height: 2.5rem;
            position: absolute;
            left: 2rem;
            font-size: 6px;
        }

    #jobDetails .side-bar .job-family:before {
        content: url("../img/job-details-icons/job-family.svg");
    }

    #jobDetails .side-bar .job-title:before {
        content: url("../img/job-details-icons/job-title.svg");
    }

    #jobDetails .side-bar .job-level:before {
        content: url("../img/job-details-icons/job-level.svg");
    }

    #jobDetails .side-bar .job-experience:before {
        /*content: url("../img/job-details-icons/job-family-experience.svg");*/
    }

    #jobDetails .side-bar .industry-experience:before {
        /*content: url("../img/job-details-icons/oil-industry-experience.svg");*/
    }

    #jobDetails .side-bar .job-type:before {
        content: url("../img/job-details-icons/employment-type.svg");
    }

    #jobDetails .side-bar .location:before {
        /*content: url("../img/job-details-icons/location.svg");*/
    }

    #jobDetails .side-bar .city:before {
        content: url("../img/job-details-icons/city.svg");
    }

    #jobDetails .side-bar .compensation:before {
        /*content: url("../img/job-details-icons/money.svg");*/
    }

    #jobDetails .side-bar .reporting:before {
        /*content: url("../img/job-details-icons/reporting.svg");*/
    }

    #jobDetails .side-bar .reporting p {
        margin-bottom: 0;
    }

#jobDetails .expectations {
    list-style: disc;
    text-indent: -1.2rem;
    padding-left: 0;
    list-style-position: outside;
}

.side-bar .btn {
    color: #FFF !important;
    width: 100%;
    border-radius:30px;
}

#jobDetails .employer img {
    float: left;
    margin-bottom: 1rem;
}

#jobDetails .employer h4 {
    font-size: 1rem;
    line-height: 2rem;
    color: #000;
    display: inline-block;
}


    #jobDetails .employer h4 span {
        color: black;
    }

#jobDetails .employer ul {
    list-style: none;
    padding-left: 0 !important;
    margin-bottom: 3rem;
    clear: left;
}

    #jobDetails .employer ul li {
        list-style: none;
        padding-left: 0 !important;
        text-indent: 0 !important;
        font-weight: 600;
    }

        #jobDetails .employer ul li:before {
            content: none;
        }

        #jobDetails .employer ul li span {
            font-weight: 300;
            float: right;
        }

#jobDetails .resp::before {
    content: "" !important;
}

#jobDetails .other-para p {
    list-style: none;
    font-size: 13px;
    font-weight: 300;
    margin-bottom: 1rem;
}

.jobdetails-inner-icons {
    color: #d8c388;
    left: 33px;
    position: absolute;
    font-size: 18px;
}
.reset-page-wrap {
    height: calc(100vh - 141px);
}


.top-section-job-details {
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;
    width: 75%;
    flex-direction: column;
}
.top-family-location{
    display:flex;
}
#jobDetails hr {
    margin: 3rem 0;
}
/************************************** APPLICATION FORM PAGE STYLES **************************************/
#applicationForm{
    padding:130px 0px 70px 0px;
}
.application-form {
    background: var(--mnr-light-grey);
    padding: 2.5rem;
    margin-bottom: 3rem;
    border-radius: 15px;
}

    .application-form label, .application-form p, .application-form #applicantCv {
        color: var(--mnr-dark-grey);
        font-size: 16px;
        font-weight: 400;
    }

    .application-form input, .application-form textarea {
        box-sizing: border-box;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid rgba(177, 176, 176, 0.25);
        border-radius: 0;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.02);
        margin-bottom: 3rem;
    }

    .application-form textarea {
        height: auto;
    }

    .application-form input::placeholder {
        color: var(--mnr-light-grey);
        font-size: 16px;
        filter: brightness(.9);
    }

    .application-form [type="file"] {
        height: auto;
    }

    .application-form [type="checkbox"] {
        height: 17px;
        margin-right: .5rem;
    }

    .application-form p {
        margin-bottom: 1rem;
    }

    .application-form .btn {
        color: #FFF !important;
        margin-top: 15px;
        float:right;
    }
.application-sidebar {
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
}

.job-details-apply {
    color: var(--mnr-dark-grey);
    font-size: 16px;
    font-weight: 400;
    padding-left:100px;
}
    .job-details-apply div {
        margin-bottom: 1rem;
        text-indent:-25px;
    }
    .job-details-apply svg {
        margin-right: 8px;
        color: var(--mnr-green);
    }
.job-info-application {
    color: #2e2c2c;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 20px;
    padding-left:80px;
    /*text-align: center;*/
}

.application-stakeholder{
    cursor:pointer;
}

.apply-job-cv{
    display:flex;
    justify-content:space-between;
}

.apply-newCv{
    color:var(--mnr-green) !important;
    cursor:pointer;
}

.application-modal {
    color: var(--mnr-green);
    font-size: 14px;
    text-decoration: none;
    font-weight: 600;
}

    .application-modal:hover {
        color: var(--mnr-green);
    }
.arrow-down-application {
    width: 1px;
    background-color: hsl(0, 0%, 80%);
    margin-bottom: 8px;
    margin-top: 8px;
    box-sizing: border-box;
    position: absolute;
    right: 34px;
    top: -6%;
    font-size: 20px;
}

.application-form-input .form-control, .application-form-input .form-select {
    margin-bottom: 1rem !important;
}
/************************************** JOB LISTING PAGE STYLES **************************************/
#jobSearchWidgetListing {
    text-align: center;
    padding: 5rem 0;
}

    #jobSearchWidgetListing .job-search {
        padding: 2rem;
        position: relative;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.07);
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    }

    /*#job-search-widget .field-wrapper {
    position: relative;
}*/

    #jobSearchWidgetListing .field-wrapper .mb-3 {
        margin: 0 !important;
    }

    #jobSearchWidgetListing label, #jobSearchWidgetListing .job-search input, #jobSearchWidgetListing .job-search input::placeholder, #jobSearchWidgetListing .job-search select {
        border: none;
        font-size: 1.1rem;
        color: var(--mnr-dark-grey);
    }

    #jobSearchWidgetListing .job-search input {
        border-radius: 0;
        margin-left: 1rem;
    }

    #jobSearchWidgetListing .job-search .search-field-icon:before, #jobSearchWidgetListing .job-search .cities-field-icon:before, #jobSearchWidgetListing .job-search .job-family-field-icon:before {
        content: url("../img/magnify.svg");
        transform: scale(.8) translateY(-50%);
        display: block;
        position: absolute;
        top: 50%;
    }

    #jobSearchWidgetListing .job-search .cities-field-icon:before {
        content: url("../img/map-location.svg");
    }

    #jobSearchWidgetListing .job-search .job-family-field-icon:before {
        content: url("../img/briefcase.svg");
    }

    #jobSearchWidgetListing .field-wrapper:after {
        content: "";
        display: inline-block;
        height: 70px;
        width: 1px;
        background-color: var(--mnr-light-grey);
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    #jobSearchWidgetListing .field-wrapper:last-child:after {
        content: none;
    }

.form-select {
    background-image: url("../img/grey-caret.svg");
    background-size: 7px;
    padding-left: 30px;
}

#jobFamilyFieldListing {
    padding-left: 40px;
}

#jobSearchWidgetListing .job-search textarea:focus,
#jobSearchWidgetListing .job-search textarea.form-control:focus,
#jobSearchWidgetListing .job-search input.form-control:focus,
#jobSearchWidgetListing .job-search input[type=text]:focus,
#jobSearchWidgetListing .job-search input[type=password]:focus,
#jobSearchWidgetListing .job-search input[type=email]:focus,
#jobSearchWidgetListing .job-search input[type=number]:focus,
#jobSearchWidgetListing .job-search [type=text].form-control:focus,
#jobSearchWidgetListing .job-search [type=password].form-control:focus,
#jobSearchWidgetListing .job-search [type=email].form-control:focus,
#jobSearchWidgetListing .job-search [type=tel].form-control:focus,
#jobSearchWidgetListing .job-search [contenteditable].form-control:focus
#jobSearchWidgetListing .job-search select:focus,
#jobSearchWidgetListing .job-search #jobCitiesField,
#jobSearchWidgetListing .job-search #jobFamilyField {
    box-shadow: none !important;
}

#jobSearchWidgetListing .advanced-search {
    font-size: 1.1rem;
    background-color: none;
    color: #000000;
    margin-top: 15px;
    float: left;
}

    #jobSearchWidgetListing .advanced-search:after {
        content: url("../img/black-caret.svg");
        margin-top: -10px;
        margin-left: 15px;
    }

#jobSearchWidgetListing .find-jobs-btn {
    min-width: 120px;
}

    #jobSearchWidgetListing .find-jobs-btn, #jobSearchWidgetListing .find-jobs-btn:hover {
        background-color: var(--mnr-red);
        border-radius: 30px;
    }

#jobSearchWidgetListing .dropdown {
    width: 100%;
    padding-left: 10px;
    position: unset;
}


#jobsFilterListing {
    padding: 5rem 0 2rem 0;
}

#jobSortListing, #paginationJobListig {
    font-size: 15px;
    padding: 10px 25px 10px 15px;
    background-color: var(--mnr-light-grey);
    border: none;
    color: var(--mnr-dark-grey);
    border-radius: 30px;
}

#jobsFilterListing .results-jobListing {
    font-weight: 500;
}

.left_job_container {
    background-color: var(--mnr-light-grey);
    border-radius: 15px 15px 0px 0px;
    border: 1px solid rgba(0, 0, 0, 0.07)
}

#datePostedfilter {
    padding: 25px 25px 0px 25px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07)
}

    #datePostedfilter legend {
        font-size: 17px;
        font-weight: 700;
    }

    #datePostedfilter .date-inputs {
        display: flex;
        justify-content: left;
        align-items: center;
    }

        #datePostedfilter .date-inputs Label {
            font-size: 12px;
            font-weight: 700;
            padding: 6px;
        }

        #datePostedfilter .date-inputs .form-check-input {
            width: 1.25em;
            height: 1.25em;
            margin-top: 0;
        }

            #datePostedfilter .date-inputs .form-check-input:focus {
                border: none;
                box-shadow: unset;
            }

            #datePostedfilter .date-inputs .form-check-input:checked {
                background-color: #000000;
                border-color: #000000;
            }

#employmentTypefilter {
    padding: 25px 25px 15px 25px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07)
}

    #employmentTypefilter .emp_type_filter {
        font-size: 17px;
        font-weight: 700;
        margin-bottom: 1rem;
    }

    #employmentTypefilter .inner-emp-filtr div {
        padding: 3px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        font-weight: 700;
        cursor: pointer;
    }

    #employmentTypefilter .inner-emp-filtr .colored-circle {
        height: 24px;
        width: 24px;
        display: inline-block;
        border-radius: 50%;
        margin-right: 10px;
    }

    #employmentTypefilter .inner-emp-filtr .cl-1 {
        background: #f13838;
    }

    #employmentTypefilter .inner-emp-filtr .cl-2 {
        background: #45d745;
    }

    #employmentTypefilter .inner-emp-filtr .cl-3 {
        background: #4848b3;
    }

    #employmentTypefilter .inner-emp-filtr .cl-4 {
        background: #f1c010;
    }

    #employmentTypefilter .inner-emp-filtr .cl-5 {
        background: #e7932a;
    }

    #employmentTypefilter .inner-emp-filtr .cl-6 {
        background: #4ddfa2;
    }

#jobFamilyfilter {
    padding: 25px 25px 15px 25px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07)
}

    #jobFamilyfilter .job_fam_filter {
        font-size: 17px;
        font-weight: 700;
        margin-bottom: 1rem;
    }

    #jobFamilyfilter .job-fam-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        font-weight: 700;
    }

        #jobFamilyfilter .job-fam-inner Label {
            font-size: 12px;
            font-weight: 700;
            padding: 8px;
        }

        #jobFamilyfilter .job-fam-inner .form-check {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        #jobFamilyfilter .job-fam-inner .form-check-input {
            width: 1.25em;
            height: 1.25em;
            margin-top: 0;
            border-radius: unset;
        }

            #jobFamilyfilter .job-fam-inner .form-check-input:focus {
                border-color: unset;
                box-shadow: unset;
            }

            #jobFamilyfilter .job-fam-inner .form-check-input:checked {
                background-color: var(--mnr-red);
                border-color: var(--mnr-red);
            }

    #jobFamilyfilter .jobfamily-red {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        font-weight: 700;
        color: var(--mnr-red)
    }

        #jobFamilyfilter .jobfamily-red Label {
            font-size: 12px;
            font-weight: 700;
            padding: 8px;
            color: var(--mnr-red)
        }

        #jobFamilyfilter .jobfamily-red .form-check {
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--mnr-red)
        }

        #jobFamilyfilter .jobfamily-red .form-check-input {
            width: 1.25em;
            height: 1.25em;
            margin-top: 0;
            border-radius: unset;
        }

            #jobFamilyfilter .jobfamily-red .form-check-input:focus {
                border-color: unset;
                box-shadow: unset;
            }

            #jobFamilyfilter .jobfamily-red .form-check-input:checked {
                background-color: var(--mnr-red);
                border-color: var(--mnr-red);
            }



#expandfilters .accordion-button {
    padding: 5px 25px;
    background-color: #eee;
    font-size: 17px;
    font-weight: 600;
    color: #b3a5a5;
}

    #expandfilters .accordion-button:focus {
        box-shadow: unset;
    }

    #expandfilters .accordion-button:not(.collapsed) {
        box-shadow: unset;
    }

#expandfilters .accordion-body {
    padding: 0;
}

#expandfilters .accordion-button::after {
    background-image: url("../img/add.png");
}

#expandfilters .accordion-button:not(.collapsed)::after {
    background-image: url("../img/minus.png");
}

#innerexpandfilters .accordion-button {
    background-color: transparent;
}

    #innerexpandfilters .accordion-button:not(.collapsed) {
        box-shadow: unset;
        color: #000000;
        padding: 5px 25px;
    }

#innerexpandfilters .offered-salary-filter {
    padding: 0px 25px 25px;
}

#innerexpandfilters .accordion-button::after {
    background-image: url("../img/down.png");
}

#innerexpandfilters .accordion-button:not(.collapsed)::after {
    background-image: url("../img/down.png");
}

#innerexpandfilters .offered-salary-filter .salary-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
}

    #innerexpandfilters .offered-salary-filter .salary-inner Label {
        font-size: 12px;
        font-weight: 700;
        padding: 10px;
    }

    #innerexpandfilters .offered-salary-filter .salary-inner .form-check {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #innerexpandfilters .offered-salary-filter .salary-inner .form-check-input {
        width: 1.25em;
        height: 1.25em;
        margin-top: 0;
        border-radius: unset;
    }

        #innerexpandfilters .offered-salary-filter .salary-inner .form-check-input:focus {
            border-color: unset;
            box-shadow: unset;
        }

        #innerexpandfilters .offered-salary-filter .salary-inner .form-check-input:checked {
            background-color: var(--mnr-red);
            border-color: var(--mnr-red);
        }

#innerexpandfilters .offered-salary-filter .salary-inner-red {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
    color: var(--mnr-red)
}

    #innerexpandfilters .offered-salary-filter .salary-inner-red Label {
        font-size: 12px;
        font-weight: 700;
        padding: 10px;
    }

    #innerexpandfilters .offered-salary-filter .salary-inner-red .form-check {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #innerexpandfilters .offered-salary-filter .salary-inner-red .form-check-input {
        width: 1.25em;
        height: 1.25em;
        margin-top: 0;
        border-radius: unset;
    }

        #innerexpandfilters .offered-salary-filter .salary-inner-red .form-check-input:focus {
            border-color: unset;
            box-shadow: unset;
        }

        #innerexpandfilters .offered-salary-filter .salary-inner-red .form-check-input:checked {
            background-color: var(--mnr-red);
            border-color: var(--mnr-red);
        }

#jobcardlisting {
    margin-bottom: 20px;
}

    #jobcardlisting:nth-child(2n) .card-body {
        background: var(--mnr-light-grey);
        border-radius: 10px;
    }

    #jobcardlisting .job-card-head {
        font-size: 22px;
        font-weight: 700;
    }

        #jobcardlisting .job-card-head span {
            font-size: 12px;
            padding: 2px 20px;
            margin: 0px 5px;
            border-radius: 15px;
            font-weight: 400
        }

        #jobcardlisting .job-card-head .featured {
            padding: 0px 0px 0px 12px;
            font-size: 14px;
            color: var(--mnr-red);
            font-weight: 600;
        }

        #jobcardlisting .job-card-head .fulltime {
            background-color: var(--mnr-green);
            color: #ffffff;
        }

        #jobcardlisting .job-card-head .urgent {
            background-color: var(--mnr-gold);
            color: #ffffff;
        }

    #jobcardlisting .job-card-data {
        font-size: 10px;
        color: var(--mnr-dark-grey);
    }

        #jobcardlisting .job-card-data span {
            line-height: 24px;
            margin-right: 30px;
        }

    #jobcardlisting .card-body {
        padding: 20px
    }

    #jobcardlisting .job-card-para {
        font-weight: 500;
        font-size: 17px;
        padding: 15px 10px;
    }

    #jobcardlisting .job-card-img {
        height: 70px;
        width: 100%;
    }

    #jobcardlisting .shortlist-job-card {
        font-size: 13px;
        color: var(--mnr-dark-grey);
    }

        #jobcardlisting .shortlist-job-card .heart-icon-jobcard {
            font-size: 20px;
            color: #e30303;
            margin: 8px 6px 10px 6px;
        }

    #jobcardlisting .job-card-data b {
        margin-left: 7px;
    }

    #jobcardlisting .job-card-data span img {
        height: 15px
    }

/*reset password css*/
.pssword-inp-reset-screens {
    position: relative !important;
}

.reset-icon-password-visible {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 22px;
    color: #9b9797;
    cursor: pointer;
}

.reset-password-submit{
    border-radius:30px;
}
/************************************** NEW JOBS PAGE STYLES **************************************/
#jobSearchWidget {
    text-align: center;
    padding: 6.5rem 0 2rem 0;
}

    #jobSearchWidget .job-search {
        padding: 2rem;
        position: relative;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.07);
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    }

    #jobSearchWidget .field-wrapper:after {
        content: "";
        display: inline-block;
        height: 70px;
        width: 1px;
        background-color: var(--mnr-light-grey);
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    #jobSearchWidget .find-jobs-btn1 {
        font-size: 15px !important;
        font-weight: 600;
        color: #ffffff;
        background-color: var(--mnr-red) !important;
        border: none;
        border-radius: 50px;
        margin: 0 5px;
        padding: 15px 25px !important;
        transition: all ease 0.3s;
    }

.jobsFilter {
    padding: 5rem 0 5rem 0;
}

#jobsFilter .container .showing-results p {
    /*font-weight: 600;*/
    font-weight: 300;
    line-height: 1.5rem;
    /*margin-bottom: 2rem !important;*/
}

#jobsFilter .container .row div {
    display: flex;
    align-items: center;
    margin-bottom: 1rem
}

    #jobsFilter .container .row div div {
        margin-bottom: 0;
    }

    #jobsFilter .container .row div p {
        margin-bottom: 0;
    }

#jobsFilter .container .row .select div {
    margin-bottom: 0 !important;
}

#jobsFilter .jobSort, #jobsFilter .noOfPage {
    border-radius: 45px;
    margin-bottom: 0;
}

.card-columns a {
    text-decoration: none !important;
}
#jobsList1{
    padding-bottom:4rem;
}
#jobsList1 .card-columns .cardDiv .card:hover {
    background: var(--mnr-light-grey);
    opacity: 0.7 !important;
    cursor: pointer;
}

#jobsList1 .card {
    border: 1px solid rgba(0, 0, 0, 0.07);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.02);
    border-radius: 15px;
    padding: 1.5rem;
    margin-bottom: 2rem;
}

#jobsList1 .odd {
    background: #FFFFFF;
}

#jobsList1 .even {
    background: var(--mnr-light-grey);
}

#jobsList1 .card h3 {
    color: #000000;
    font-size: 1.3rem;
    display: flex;
}

#jobsList1 .card-img {
    width: 12%;
    float: right;
    margin-left: 30px;
    margin-bottom: 15px;
}

#jobsList1 .card h3 .badges-wrapper {
    margin-left: 10px;
}

#jobsList1 .badge {
    border-radius: 50px;
    font-size: 10px;
    font-weight: 400;
    padding: 5px 10px;
    margin: 0 5px;
}

#jobsList1 .green {
    background: var(--mnr-green);
}

#jobsList1 .gold {
    background: var(--mnr-gold);
}

#jobsList1 .featured {
    color: var(--mnr-red);
    font-size: 12px;
    font-weight: 400;
    margin: 0 5px;
}

#jobsList1 .card-text strong {
    padding-left: 25px;
}


    #jobsList1 .card-text i strong {
        padding-left: 8px;
        font-weight: bold;
    }
    #jobsList1 .card-text .strong-text {
        padding-left: 8px;
        font-weight: bold;
    }

    #jobsList1 .card-text .paddingleft5 {
        padding-left: 5px;
    }

#jobsList1 .card-text {
    font-size: 13px;
    color: var(--mnr-dark-grey);
    margin-top: 1.5rem;
}

    #jobsList1 .card-text div {
        margin-right: 20px !important;
        /*margin-bottom: 1rem;*/
        display: inline-block;
    }

    #jobsList1 .card-text .job-desc {
        margin-right: 20px !important;
        /*margin-bottom: 1rem;*/
        display: block;
    }

    #jobsList1 .card-text .icons {
        position: relative;
        margin-right: 20px !important;
        margin-bottom: 1rem;
        display: inline-block;
    }

        #jobsList1 .card-text .icons:before {
            top: 2px;
        }

    #jobsList1 .card-text .job-salary {
        margin-right: 0;
    }

    #jobsList1 .card-text div:before {
        transform: scale(.7);
        position: absolute;
        margin-top: -5px;
        margin-left: 0;
    }

    #jobsList1 .card-text .job-qualification {
        margin-left: 4px;
    }

    #jobsList1 .card-text .listing strong {
        padding-left:6px !important;
    }

#jobsList1 .job-desc p {
    color: #000;
    font-size: .8rem;
    font-weight: 400;
    margin-bottom: 0 !important;
    margin-top: 1rem;
    line-height: 1.5rem;
    /*text-align: justify;*/
}
/* Sidebar */
#jobsList1 .sidebar {
    border: 1px solid rgba(0, 0, 0, 0.07);
    box-shadow: 0px 0px 10px rgb(0 0 0 / 2%);
    border-radius: 15px;
    background: var(--mnr-light-grey);
    margin-bottom: 2rem;
    padding: 1.5rem
}

#jobsList1 .date-section, #jobsList1 .employment-section {
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}
    #jobsList1 .date-section, #jobsList1 .internship-family {
        border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    }
#jobsList1 .employment-section {
    margin-bottom: 2rem;
}
    #jobsList1 .sidebar h4 {
        color: #000;
        text-transform: uppercase;
        font-size: 0.9rem;
        font-weight: 600;
        line-height: 2rem;
    }
#jobsList1 .sidebar .employment-section .form-check input.form-check-input{
    margin-left:0;
}

#jobsList1 .sidebar .form-check {
    margin-bottom: 0.5rem;
}

#jobsList1 .form-check {
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5em;
    margin-bottom: 0.125rem;
}

#jobsList1 .sidebar .form-check-input {
    border: 1px solid rgba(0, 0, 0, 0.1);
    width: 1rem;
    height: 1rem;
}

    #jobsList1 .sidebar .form-check-input:checked {
        background-color: var(--mnr-red);
        border: none;
    }

#jobsList1 .box-checked input[type=checkbox]:checked + label {
    /*color: #db333b;*/
}

#jobsList1 .sidebar .form-check-input:focus {
    box-shadow: none;
}

#jobsList1 .sidebar label {
    font-size: .7rem;
    width: 100%;
    position:relative
}

    #jobsList1 .sidebar label span {
        float: right;
    }
#jobsList1 .sidebar .expand-title {
    font-size: .9rem;
    text-transform: uppercase;
    background: #fff;
    border: 1px solid rgba(0,0,0,.1);
    color: var(--mnr-dark-grey) !important;
    cursor: pointer;
    padding: 0.6rem 1.5rem;
    margin-bottom: 0;
    line-height: 2rem;
    margin-bottom: 0.5rem;
    text-align: unset;
}

#jobsList1 .expand-filters .main-title {
    background: #ededed;
    color: var(--mnr-dark-grey) !important;
    padding: 0.6rem 1.5rem;
    cursor: pointer;
    margin-bottom: 0;
}

    #jobsList1 .expand-filters .main-title:after {
        transform: scale(1.2);
        float: right;
        background-image: url('../img/add.png');
        background-size: 15px 15px;
        display: inline-block;
        width: 15px;
        height: 15px;
        content: "";
    }

#jobsList1 .expand-title {
    color: var(--mnr-dark-grey) !important;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0.6rem 1.5rem;
    background: #ffffff;
    cursor: pointer;
    margin-bottom: 0;
}

    #jobsList1 .expand-title:after {
        content: url("../img/chevron-down.svg");
        transform: scale(1.2);
        float: right;
    }

#jobsList1 .sidebar .date-section div, #jobsList1 .sidebar .employment-section div {
    display: flex;
    min-height: 1.5rem;
    align-items: center;
    padding: 0;
}
    #jobsList1 .sidebar .date-section div input[type='radio'] {
        margin-right: 6px;
    }
        #jobsList1 .sidebar .date-section div input[type='radio']:after {
            width: 16px;
            height: 16px;
            cursor: pointer;
            border-radius: 50%;
            top: -1px;
            left: -2px;
            position: relative;
            background-color: white;
            content: '';
            display: inline-block;
            visibility: visible;
            /*border: 1px solid white;*/
            border: 1px solid rgba(0,0,0,.1);
        }

        #jobsList1 .sidebar .date-section div input[type='radio']:checked:after {
            width: 16px;
            height: 16px;
            cursor: pointer;
            border-radius: 50%;
            top: -1px;
            left: -2px;
            position: relative;
            content: '';
            display: inline-block;
            visibility: visible;
            /*background-color: black;*/
            /*border: 2px solid white;*/
            border: 4px solid var(--mnr-red);
            background-color: white;
        }
.label-input-checker {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.filter-check-label {
    display: flex;
    align-items: center;
}
    .filter-check-label input{
        margin-right:5px
    }
    #jobsList1 .sidebar .employment-section div input[type='radio']:after {
        cursor: pointer;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        top: -4px;
        left: -3px;
        position: relative;
        background-color: #d1d3d1;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 1px solid white;
    }

    #jobsList1 .sidebar .employment-section div input[type='radio']:checked:after {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        top: -4px;
        left: -3px;
        position: relative;
        background-color: #db333b;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 1px solid white;
    }
/*#jobsList1 .sidebar .employment-section .freelancer input[type='radio']:checked:after {
    background-color :#db333b ;
}*/
#jobsList1 .sidebar .employment-section .fulltime input[type='radio']:checked:after {
    background-color: #72c02c;
}

#jobsList1 .sidebar .employment-section .internship input[type='radio']:checked:after {
    background-color: #164e9b;
}

#jobsList1 .sidebar .employment-section .parttime input[type='radio']:checked:after {
    background-color: #ffbe2c;
}

#jobsList1 .sidebar .employment-section .temporary input[type='radio']:checked:after {
    background-color: #ed823a;
}

#jobsList1 .sidebar .employment-section .volunteer input[type='radio']:checked:after {
    background-color: #80c4a4;
}

#jobsList1 .sidebar .employment-section div label {
    margin-left: 10px;
}

#jobsList1 .sidebar .date-section div label {
    margin-left: 10px;
}

#jobsList1 .sidebar .expand-title2 {
    background: #FAF9F9;
    cursor: pointer;
    margin-top: 25px;
    padding: 0 1.5rem;
}

#jobsList1 .expand-title2:after {
    content: url("../img/chevron-down.svg");
    transform: scale(1.2);
    float: right;
}

.expand-section collapse show div collapse show {
    background-color: red;
}
#jobsList1 .sidebar .expand-section .career-level{
    padding: 0rem 1.5rem;
}
    #jobsList1 .sidebar .expand-section .career-level .heading {
        font-size: .9rem;
        text-transform: uppercase;
        background: #fff;
        border: 1px solid rgba(0,0,0,.1);
        color: var(--mnr-dark-grey) !important;
        cursor: pointer;
        padding: 0.6rem 1.5rem;
        margin-bottom: 0;
        line-height: 2rem;
        margin-bottom: 0.5rem;
    }
    #jobsList1 .sidebar .expand-section .career-level .heading .heading-section {
        padding-left: 0.5rem !important;
        padding-top: 1rem !important;
    }

/*//////////////////////*/
#jobSearchWidget .job-search .field-wrapper .react-select .basic-multi-select {
    margin-left: 40px;
}
#jobSearchWidget .job-search .field-wrapper .react-select .select__control {
    border: none;
    margin-bottom: 0;
    width: 15rem;
}
/*#jobSearchWidget .job-search .field-wrapper .react-select .select__indicators,*/ 
#jobSearchWidget .job-search .field-wrapper .react-select .select__indicators span {
    display: none;
}
#jobSearchWidget .react-select .select__control .select__value-container .select__placeholder {
    color: var(--mnr-dark-grey);
    margin-right: 9rem;
    font-size: 1.1rem;
}
#jobSearchWidget .job-search .field-wrapper .react-select .select__value-container {
    padding: 0;
    align-items: center;
    justify-content: center;
    /*max-height: 60px;*/
}
/************************************** NEW JOBS PAGE STYLES END **************************************/

/************************************** COURSES PAGE STYLES **************************************/

.sidebar .title {
    font-size: .8rem;
    font-weight: 600;
    margin-bottom: .5rem;
    cursor: pointer;
}
.sidebar .desc {
    font-size: .7rem;
    font-weight: 400;
    margin-bottom: .5rem;
    cursor: pointer;
}

.courses-info-section, .course-type-section, .course-category-section, .courses-section {
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    margin-bottom: 2rem;
}

.recent-courses {
    border-bottom: none;
    margin-bottom: 0;
}

.courses-info-section h4, .courses-section h4 {
    margin-bottom: 1rem;
}

.courses-section a {
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);
    margin-bottom: 1.5rem;
    padding-bottom: 2rem;
    display: block;
}

.courses-section img {
    border-radius: 10px;
    width: 60px;
    height: 60px;
    float: left;
    object-fit: contain;
    margin-right: 20px;
    cursor: pointer;
}

.courses-section .badge, .course .badge {
    border-radius: 50px;
    font-size: 10px;
    font-weight: 400;
    padding: 5px 10px;
    margin: 0 0 10px 0 !important;
    display: inline-block;
    float: none;
}

course .badge {
    float: left !important;
}

.courses-section .free, .course .free {
    background: var(--mnr-green);
}

.courses-section .paid, .course .paid {
    background: var(--mnr-red);
}

.courses-section a {
    text-decoration: none;
    color: #000000 !important;
    font-weight: 900 !important;
}

    .courses-section a:hover {
        color: var(--mnr-red) !important;
    }

.course .card h3 {
    margin-bottom: 0;
}

.course .card .badges-wrapper {
    margin-bottom: 1rem;
}

.course img {
    width: 100% !important;
   /* height: 150px;*/
   /* object-fit:fill;*/
   /* border-radius: 100%;*/
    margin-bottom: 0rem !important;
    border-bottom-right-radius:initial;
    border-bottom-left-radius:initial;
    border-top-left-radius:initial;
    border-top-right-radius:initial;
}

.badges-wrapper-courses {
    margin-top: 6px;
    margin-left: 6px;
    margin-bottom:0 !important;
}
.courses-card-text {
    margin-top: 10px !important;
}

.courses-card-text .icons{
    margin-top:0.5rem !important;
    margin-bottom:0 !important;
}

.courses-stakeholder {
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    margin-bottom: 2rem;
    margin-top: 1rem;
    padding-bottom: 2rem !important;
}
.image-initials {
    border: 3px solid var(--mnr-dark-grey);
    border-radius: 50%;
    height: 140px;
    width: 140px;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    font-size: 50px;
    color: var(--mnr-dark-grey);
}

.image-initials-popular {
    border: 3px solid var(--mnr-dark-grey);
    border-radius: 50%;
    font-size: 20px;
    color: var(--mnr-dark-grey);
    display: block;
    float: left;
    margin-right: 20px;
    height: 60px;
    width: 60px;
}
.image-initials-popular span {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.jobs-img-initials{
    float:right !important;
}

.job-view-initials {
    height: 100px;
    width: 110px;
    margin-bottom: 1rem;
}

/* General styles */

.marginleft80{
    margin-left:80px;
}
.outerdiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
}
.paddingbottom10{
    padding-bottom:10px;
}
.disabled {
    opacity: 0.6;
    pointer-events: none;
    border-color:transparent;
}

.fontsize-dropdown{
    font-size:13px !important;
    word-wrap: break-word;
}

.left-align
{
    float:left;
    text-align:left;
    clear:both;
    width:100%;
    padding: 2px 2px !important;
}

#react-select-default-listbox {
    padding: 0 !important;
    margin-left: 0 !important;
}

#react-select-300-listbox {
    width: 300px !important;
    padding: 0 !important;
    margin-left: 0 !important;
}

#react-select-350-listbox {
    width: 350px !important;
    padding: 0 !important;
    margin-left: 0 !important;
}

#react-select-400-listbox {
    width: 400px !important;
    padding: 0 !important;
    margin-left: 0 !important;
}

#react-select-450-listbox {
    width: 450px !important;
    padding: 0 !important;
    margin-left: 0 !important;
}

#react-select-500-listbox {
    width: 500px !important;
    padding: 0 !important;
    margin-left: 0 !important;
}

#react-select-550-listbox {
    width: 550px !important;
    padding: 0 !important;
    margin-left: 0 !important;
}



.coltextwidth {
    width: 20% !important;
}

.colwidth
{
    width:16% !important;
}

.applied-filter {
    margin-top:-30px;
    margin-bottom:15px;
}

.chkOption {
    float: left;
    clear: both;
    margin: 7px 5px 0px 0px !important;
}

.background-grey {
    background-color: rgb(235, 235, 228);
}

.background-grey:hover {
    background-color: rgb(235, 235, 228) !important;
}

.background-blue {
    background-color: blue;
}

.marginleft10
{
    margin-left: 10px !important;
}
.marginleft20 {
    margin-left: 20px !important;
}

.margintop10 {
    margin-top: 10px !important;
}

.marginbottom10 {
    margin-bottom: 10px !important;
}

.paddingtop10 {
    padding-top: 10px !important;
}

.paddingbottom15 {
    padding-bottom: 15px !important;
}

.selected-select-control {
    border: 1px solid red !important;
    border-radius: 4px;
}

.nodata
{
    width: 100%;
    text-align: center;
}

.withdraw-link {
    color: var(--mnr-green) !important;
    font-size: 14px;
    border: none;
    background: transparent;
    text-decoration:none;
}

.border0 {
    border: 0 !important;
}

.floatleft {
    float: left;
}

.floatright {
    float: right;
}

.coursesleft {
    padding: 20px 20px 1px 20px !important;
    border-radius: 5px;
    margin-bottom: 10px;
}

.coursesleft a {
    border: 0 !important;
    padding-bottom: 0px !important;
}

.search-close {
    cursor: pointer;
    color: red;
    text-decoration: none;
    font-weight: bold;
    font-size: 22px;
}

.width5pc {
    width: 5% !important;
}

.searchwithclose {
    display: inline-flex;
    float: left;
}
.internColwidth{
    width: 25%;
}