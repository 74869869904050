* {
    padding: 0;
    margin: 0;
}

.loader-main-section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgba(0,0,0,0.6);
    position: fixed;
    z-index: 1;
}

.spinner-section {
    color: #fff;
    width: 60px;
    height:60px;
}
