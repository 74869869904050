/* Styles for smaller sceen sizes */

/*@media (min-width: 768px) {
    body {
        font-size: 16px;
    }
}*/

.navbar-toggler {
    background-color: var(--mnr-gold);
}

    .navbar-toggler span {
        color: white !important;
    }

@media (max-width: 768px) {

    .logo {
        max-width: 250px !important;
    }

    /* Navbar buttons */
    .sign-in, .sign-up {
        margin: 10px 0 0 0 !important;
        display: lock;
        text-align: center;
    }

    /* Hero */

    #hero .lead {
        line-height: 2rem;
    }

    #hero .btn {
        min-width: 100% !important;
    }

    /* Hiring */
    .hiring-process-wrapper:after {
        display: none;
    }

    /* Job search widget */

    .field-wrapper {
        flex: none !important;
        display: block !important;
    }

    #jobSearchWidget .job-search .search-field-icon:before, #jobSearchWidget .job-search .cities-field-icon:before, #jobSearchWidget .job-search .job-family-field-icon:before {
        transform: scale(.6) translateY(0);
        top: unset;
        margin-top: 5px;
    }

    #jobSearchWidget .find-jobs-btn {
        width: 100% !important;
        margin-top: 2rem;
    }

    #jobSearchWidget .mega-menu {
        transform: translate3d(0, 400px, 0) !important;
    }

        /* Job list */
        #jobSearchWidget .mega-menu .form-select, #jobsFilter .form-select {
            width: 100%;
        }

    .showing-results p {
        margin-bottom: .5rem;
        text-align: center;
    }
    #jobsList1 {
        padding-bottom: 9rem;
    }

    #jobDetails .employer-company {
        margin-bottom: 10rem !important;
    }
    #jobsList .card h3 {
        clear: left;
    }

    #jobsList .card img {
        width: 50%;
    }

    #jobsList .card h3 .featured {
        display: inline-block;
    }

    #jobsList .card .card-text span {
        display: block;
        margin-bottom: 20px;
        margin-left: 0;
        padding-left: 0;
    }

    #jobsList .card .card-text .job-links {
        float: left;
        margin-top: 20px;
    }

        #jobsList .card .card-text .job-links span {
            float: left;
            padding: 0;
            margin-right: 25px;
        }

    #jobsList .card-img, #jobDetailsIntro .card-img, #similarJobsList .card-img {
        width: 35%;
        float: none;
        margin-left: 0;
      /*  margin-bottom:15px*/
    }

    .card-login-50 {
        width: 100% !important;
        padding: 15px 15px !important;
    }

    .card-login h1 {
        font-size: 20px !important;
        margin-bottom: 10px;
    }
    .similar-jobs {
        padding-bottom: 11rem !important;
    }
    #jobSearchWidget .find-jobs-btn1{
        width:100%;
    }
    #jobsFilter .container .row div{
        display:block;
        width:100%;
    }
    .perpage-resp{
        margin-left:0 !important;
        margin-top:15px;
    }

    #jobsList1 .card-img {
        width: 50%;
        float: none;
        margin-left: 0;
        margin-bottom: 20px;
        height: 100px;
        object-fit: contain;
    }
    #jobsList1 .card h3 {
        display: block;
    }
        #jobsList1 .card h3 .badges-wrapper {
            margin-left:0;
            margin-top: 15px;
        }
    #jobsList1 .card-text .icons {
        display: block;
        margin-bottom: 1.2rem;
    }
    #jobDetailsIntro .card .cardImg{
        width:35%
    }
    #jobDetails ul li {
        line-height: 2rem;
    }

    #jobDetails .side-bar .job-detail{
        margin-bottom:3rem
    }
    .top-section-job-details{
        width:100%;
    }
    #jobDetailsIntro .apply {
        position: unset;
        margin: 2rem 0 0 0;
    }
    #jobDetailsIntro .shortlist {
        float: none;
        margin: -1rem 0 0 0;
        display: block;
    }
    #similarJobsList .card {
        padding: 0.5rem;
    }
    #similarJobsList .card-text {
        display: block;
        margin-top: 15px;
    }
        #similarJobsList .card-text span{
            margin-left:20px !important;
            margin-bottom:.5rem !important;
        }
        #similarJobsList .card-text .section-job {
            display: flex;
            flex-direction: column;
        }
        #similarJobsList .card-text .job-links span button:after {
            margin-left: 9px;
        }
        #similarJobsList .card-text .job-links span a:after {
            margin-left: 9px;
        }

    #similarJobsList .badge {
        width: fit-content;
        margin-top: 10px;
    }

    .similar-jobs-badges-resp {
        display: block;
    }
    #similarJobsIntro {
        padding: 0 0 2rem 0;
    }
    #jobsFilter {
        padding: 5rem 0 0 0;
    }

    #similarJobsList .card-text .job-links{
        float:left;
    }
    #jobDetailsIntro .card {
        padding: 3rem 0 6rem 0;
    }
    .top-family-location {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
    }
    .app-page {
        padding: 6rem 0 0rem 0 !important;
    }
    .app-form {
        padding: 0px 0px 7rem 0px !important;
    }
    .job-info-application {
        padding-left: 0;
    }

    .job-details-apply {
        padding-left: 20px;
    }

    .application-form {
        padding: 2.5rem 2.5rem 4.5rem 2.5rem;
    }
    .job-resp-search .row{
        flex-direction:column;
    }
    .job-resp-search .row .coltextwidth {
        width: 100% !important;
    }
    .job-resp-search .row .colwidth{
        width:100% !important;
    }
    .outerdiv {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .internColwidth{
        width:100%;
    }
}
