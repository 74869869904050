.error-section-container {
    padding-top: 4rem;
}

.error-section {
    margin-left: 29px;
    line-height: 2rem;
}

.error-data {
    margin: 1rem 0 1rem 4rem !important;
}
