@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

/* Body font */

/* Variables */
:root {
    --mnr-gold: #C7AB61;
    --mnr-red: #DB333B;
    --mnr-green: #72C02C;
    --mnr-yellow: #FFBC2D;
    --mnr-light-grey: #FAF9F9;
    --mnr-dark-grey: #B1B0B0;
}

body {
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
}

.candidate-profile-details {
    padding-bottom: 2.5rem;
}

    .candidate-profile-details h3 {
        margin-bottom: 0 !important;
        font-size: 15px !important;
        line-height: 2rem;
        font-weight: 600;
    }

    .candidate-profile-details p {
        font-size: 13px !important;
    }

.profile-tabs-heading {
    margin-bottom: 20px;
    font-size: 19px;
    font-weight: 600;
}

.profile-details-section .card .card-body .card-text svg {
    font-size: 11px !important;
    margin: 0px 5px 0px 0px !important;
}

.profile-inner-icons {
    color: var(--mnr-red);
    left: 33px;
    position: absolute;
    font-size: 18px;
}
.download-button-clabels{
    position:relative;
}
.download-button-cprofile {
    background: transparent;
    border-radius: 30px;
    padding: 3px 10px;
    border: 1px solid var(--mnr-red);
    color: var(--mnr-red);
    font-size: 12px;
}

    .download-button-cprofile svg {
        margin-left: 6px;
        color: var(--mnr-red);
    }
.edit-button-cprofile {
    background: var(--mnr-red);
    border-radius: 30px;
    padding: 2px 10px;
    border: 1px solid var(--mnr-red);
    color: #fff;
    font-size: 12px;
    line-height: 1.5rem;
}
.general-edit-btn {
    margin: 7px 32px;
}
.edit-button-cprofile svg {
    margin-left: 6px;
    color: #fff;
}
.jobfamily-profile {
    text-indent: -1.1rem;
    padding-left: 0 !important
}

.profile-details-section .card-title {
    text-transform: capitalize;
}

.job-application-table thead th {
    font-size: 14px;
}

.job-application-table tbody td {
    font-size: 13px;
}

.application-data-profile {
    border-left: 1px solid #ccc;
    padding-left: 30px;
}

.job-application-candidate h3 {
    color: #000;
    font-size: 19px !important;
    margin-bottom: 1.5rem !important;
}

.job-application-candidate h4::before {
    content: "";
    height: 20px;
    background-color: var(--mnr-red);
    width: 20px;
    border-radius: 50%;
    border: 5px solid var(--mnr-dark-grey);
    display: inline-block;
    position: absolute;
    left: -40px;
    top: 0px;
}

.job-application-candidate h4 {
    color: #000;
    margin-bottom: 3px;
    font-size: 15px;
    font-weight: 600;
}

.application-data-profile div {
    position: relative;
}

.job-application-candidate span {
    color: var(--mnr-red);
    font-size: .7rem;
    margin-left: 0.2rem;
}

.application-date-candidate {
    color: var(--mnr-dark-grey);
    font-size: .7rem;
    margin-bottom: 0.3rem;
    font-weight: 300;
    line-height: 1.5rem;
}

.profile-apply-cv {
    color: var(--mnr-red);
    text-decoration: none;
    cursor: pointer;
}

    .profile-apply-cv:hover {
        color: var(--mnr-red);
    }

.profile-navigation {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
}

    .profile-navigation h4 {
        cursor: pointer;
    }

    .profile-navigation span {
        cursor: pointer;
    }

.profile-details-section {
    z-index: 1;
    position: relative;
}

.profile-details-section-new {
    z-index: -1;
    position: relative;
}

.main-section-label-download{
    margin-bottom:0.7rem;
}
.edit-profile-step1{
    float:right;
    cursor:pointer;
}
.course-start-date{
    margin-bottom: 0 !important;
}



.jobapplications, .internapplications, .courseregistrations {
    color: var(--mnr-green);
    font-size: 14px;
    text-decoration: none;
}

    .jobapplications:hover, .internapplications:hover, .courseregistrations:hover {
        color: var(--mnr-green);
        cursor: pointer;
    }

.jobapplication-cv, .internshipapplication-cv {
    color: var(--mnr-green);
    text-decoration: none;
    cursor: pointer;
}

    .jobapplication-cv:hover, .internshipapplication-cv:hover {
        color: var(--mnr-green);
    }