.header-navbar {
    background-color: #fff;
    box-shadow: 0 0.25rem 0.75rem rgb(0 0 0 / 5%);
    position: fixed;
    width: 100%;
    display: block;
    z-index: 99999;
}
.header-navbar-modal {
    background-color: #fff;
    box-shadow: 0 0.25rem 0.75rem rgb(0 0 0 / 5%);
    position: fixed;
    width: 100%;
    display: block;
    z-index: unset;
}
.header-container{
    display: flex;
    align-items: center;
}
.header-brand{
    display: flex !important;
}
.header-container .navbar-nav {
    align-items: center !important;
    width: 100%;
    justify-content: flex-end;
    display: -webkit-box;
    -webkit-box-pack: end; 
    -webkit-box-align: center;
    -webkit-justify-content:flex-end;
    -webkit-align-items:center;
}
.logoimg {
    height: 50px;
}
.header-link {
    color: #000 !important;
    font-weight: 500 !important;
    margin: 10px !important;
}
    .header-link .btn {
        color: #000 !important;
        background: #fff;
        border: none;
        font-weight: 500;
        font-size: 15px;
        padding: 0;
        text-transform: unset;
        letter-spacing: unset;
    }
        .header-link .btn:active {
            color: #000 !important;
            background: #fff;
        }
        .header-buttons {
            display: flex !important;
        }

.signin-button, .signup-button {
    background-color: #fff;
    border: 1px solid var(--mnr-red);
    border-radius: 50px;
    color: var(--mnr-red) !important;
    font-size: 13px !important;
    font-weight: 600;
    margin: 0 10px;
    padding: 12px 20px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}


/*.signup-button {
    color: #fff !important;
    background-color: #72C02C !important;
    margin: 8px 4px;
    padding: 8px 20px!important;
    font-size: 13px !important;
}*/
    .signup-button a {
        color: var(--mnr-red) !important;
        text-decoration: none;
    }

.header-buttons .dropdown-toggle::after {
    color: var(--mnr-gold);
}

/*.header-buttons .btn.active, .header-buttons .btn.show, .header-buttons .btn:hover{
    background-color: transparent;
}*/
.header-profile-img{
    height:60px;
    width:60px;
    border-radius:50%;
}
.dropdown-profile button {
    /* border: none;
    padding: 0;*/
    color: #000;
    text-transform: none;
    letter-spacing: unset;
    font-size: 16px;
    font-weight: 500;
}

.dropdown-profile .btn-check:checked + .dropdown-profile .btn, :not(.btn-check) + .dropdown-profile .btn:active, .dropdown-profile .btn:first-child:active, .dropdown-profile .btn.active, .dropdown-profile .btn.show, .dropdown-profile .btn:hover {
    background-color: transparent;
    color: #000;
    text-transform: none;
    letter-spacing: unset;
    font-size: 16px;
    font-weight: 500;
    border: none;
    opacity:.7;
}
.header-profile-active {
    display: none;
}
.header-profile-inactive {
    color: #000;
    text-decoration: none;
    padding-left: 15px;
    cursor:pointer;
}


a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

.logo {
    height: auto;
    max-width: 500px;
}

.sign-in, .sign-up {
    font-size: 12px !important;
    color: white !important;
    background-color: var(--mnr-gold);
    margin: 0 5px;
    padding: 8px 20px !important;
}

.navbar-nav .nav-item .sign-in, .navbar-nav .nav-item .sign-up {
    font-size: 13px !important;
    font-weight: 600;
    color: var(--mnr-red) !important;
    background-color: #ffffff;
    border: 1px solid var(--mnr-red);
    border-radius: 50px;
    margin: 0 5px;
    padding: 12px 20px !important;
}


.sign-up {
    background-color: var(--mnr-green);
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
    border-bottom: none !important;
}

/*login modal css*/
.login-modal {
    display: flex !important;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    --bs-modal-width: 100% !important;
}

.login-modal-body {
    width: 100%;
    display: block;
    padding: 0px !important;
    position: relative;
}

    .login-modal-body .close-modal {
        position: absolute;
        right: 5px;
        top: 5px;
    }

        .login-modal-body .close-modal:active, .login-modal-body .close-modal:focus {
            box-shadow: none;
        }

.login-modal-header {
    width: 100% !important;
}

.login-modal-card-main-section {
    /* box-shadow: 0px 0px 10px 1px #cdcdcd; */
    border-radius: 15px;
    background-color: #c5c5c512;
}

.login-card-section {
    width: 100%;
}

.card-login {
    padding: 50px;
}

.card-login-50 {
    /*padding: 50px !important;*/
    padding: 25px 30px !important;
    width: 520px;
}

.card-login h1 {
    font-size: 30px;
    text-align: center;
    font-weight: 600;
    /* color: #cf3038; */
    color: #2e2c2c;
    margin-bottom: 20px;
}

.login-modal .login-modal-body .login-card-section .inputs-main-section .loginInputs {
    padding: 10px 15px !important;
    font-size: 16px !important;
    border: 1px solid #e0e2e4 !important;
    margin-bottom: 15px;
    width: 100%;
    font-weight: 400;
    line-height: 1.5;
    color: #212529 !important;
    background-color: #fff !important;
    background-clip: padding-box;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.login-card-section .inputs-main-section .loginInputs:focus-visible {
    outline: none !important;
}

.card-login .password {
    position: relative !important;
}

.card-login .icon-password-visible {
    position: absolute;
    top: 36px;
    right: 12px;
    font-size: 22px;
    color: #9b9797;
    cursor: pointer;
}

.login-modal .login-modal-body .login-card-section .inputs-main-section .loginInputs:focus {
    box-shadow: 0px 0px 5px 0px #d2d2d2 !important;
    border-color: transparent !important;
    border: 1px solid #e0e2e4 !important;
}

.login-modal .login-modal-body .login-card-section .inputs-main-section .loginLabels {
    font-size: 16px;
    color: #1d1d1d;
    font-weight: 700;
}

.forgot-password-section {
    text-align: right;
    margin-bottom: 20px;
}

    .forgot-password-section a {
        color: #000 !important;
        text-decoration: none;
        font-size: 16px;
        font-weight: 500;
    }

        .forgot-password-section a:hover {
            color: #35744a;
        }

.forget_pass a {
    color: #2e2c2c;
    cursor: pointer;
    text-decoration: underline !important;
}

.submit-button-section {
    width: 100%;
    display: flex;
    justify-content: right;
}

.sign-up-link-section h4 {
    font-size: 16px;
    color: #9b9797;
    font-weight: 600;
    text-align: center;
}

    .sign-up-link-section h4 span {
        color: #72C02C !important;
        text-decoration: none;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
    }

.hr-login-heading {
    margin-bottom: 23px !important;
    margin-top: 0px !important;
    opacity: 0.10;
}

.forgot-login-link {
    cursor: pointer;
}

.forgot-password-heading-inner h3 {
    font-size: 14px;
    color: #1d1d1d;
    font-weight: 700;
    line-height: 25px;
    margin-bottom: 22px;
}

    .forgot-password-heading-inner h3 button {
        color: var(--bs-link-color) !important;
        text-decoration: underline !important;
        background: transparent;
        border: none;
    }

.forgot-input-change {
    width: 100%;
    margin-bottom: 40px;
}

.reset-password-img-section img {
    width: 150px;
}

.reset-password-img-section {
    width: 100%;
    display: flex;
    margin: 0 auto;
    justify-content: center;
    margin-bottom: 25px;
    margin-top: 30px;
}

.resend-heading h3 {
    font-size: 14px;
    text-align: center;
}

.main-section-captcha {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
}

.dropdown-profile .btn-check:checked + .dropdown-profile .btn, :not(.btn-check) + .dropdown-profile .btn:active, .dropdown-profile .btn:first-child:active, .dropdown-profile .btn.active, .dropdown-profile .btn.show {
    background-color: transparent;
}
.logo-resp{
    display:none;
}


@media only screen and (min-width: 320px) and (max-width: 767px) {
    .logoimg2 {
        max-width: 165px;
        margin-left: 30px;
        margin-top: -15px;
        z-index:1;
        position:relative;
    }
    .logoimg1{
        max-width:295px;
    }
    .logo-main{
        display:none;
    }
    .logo-link:hover{
        opacity:1;
    }
    .logo-resp {
        display: block;
    }
    .header-brand{
        flex-direction:column;
    }
    .home-banner-inner-col {
        order: 2;
    }
    .header-container .navbar-nav{
        align-items:flex-start !important;
        display:flex;
    }
    .header-buttons{
        width:100%;
        display:block !important;
    }
    .signin-button, .signup-button{
        margin-bottom:10px;
    }
    .header-profile-btn button, .dropdown-profile button {
        padding: 8px 0px 10px 0px !important;
    }
    .card-login-50 {
        padding: 15px 15px !important;
    }
}