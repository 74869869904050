h5 {
    padding-bottom: 1rem;
}

.terms-section-container {
    padding-top: 4rem;
}

.terms-section {
    margin-left: 29px;
    line-height: 2rem;
}

.terms-row {
    padding: 3rem 0;
}

.terms-body {
    padding: 0 0 7rem 0;
}

.terms-head {
    font-size: 2.5rem;
    line-height: 3rem;
    font-weight: 600;
    padding-bottom: 3rem;
}

.terms-data {
    margin: 1rem 0 3rem 4rem !important;
}

.terms-img-checked {
    font-size: 1.5rem;
    line-height: 2.5rem;
    font-weight: 600;
    color: var(--mnr-red);
}

.terms-img-checked img {
    height: 29px;
}