@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

/* Body font */

/* Variables */
:root {
    --mnr-gold: #C7AB61;
    --mnr-red: #DB333B;
    --mnr-green: #72C02C;
    --mnr-yellow: #FFBC2D;
    --mnr-light-grey: #FAF9F9;
    --mnr-dark-grey: #B1B0B0;
}

body {
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
}

.card-login {
    padding: 50px;
    background-color: var(--mnr-light-grey)
}

.heading {
    color: #2e2c2c;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 30px;
    text-align: center;
    text-transform: capitalize;
}

.card-login ul li .nav-link {
    font-size: 20px;
    padding: 10px 30px;
}

    .card-login ul li .nav-link.active {
        background-color: #f7f7f7 !important;
        border-bottom: 2px solid #25934a !important;
        border: 0;
        color: #555 !important;
        height: 100%;
        width: 100%;
    }

.card-login ul li .nav-link {
    color: #555 !important;
    background: 0 0;
    border: var(--bs-nav-tabs-border-width) solid transparent;
    border-top-left-radius: var(--bs-nav-tabs-border-radius);
    border-top-right-radius: var(--bs-nav-tabs-border-radius);
    margin-bottom: calc(var(--bs-nav-tabs-border-width)*-1);
    height: 100%;
    width: 100%;
}

.signup-row {
    display: flex;
    justify-content: center;
}

.next-btn-signup {
    width:100%;
    border: 1px solid var(--mnr-green);
    border-radius: 30px;
    color: #fff;
    font-size: .8rem;
    letter-spacing: .1rem;
    opacity: 1;
    padding: 1rem;
    text-transform: uppercase;
    transition: all .3s ease;
    background-color: var(--mnr-green) !important;
}

    .next-btn-signup:hover {
        border-color: var(--mnr-green);
    }

.previous-btn-signup {
    width: 95%;
    border: 1px solid var(--mnr-green);
    border-radius: 30px;
    color: var(--mnr-green);
    font-size: .8rem;
    letter-spacing: .1rem;
    opacity: 1;
    padding: 1rem;
    text-transform: uppercase;
    transition: all .3s ease;
    background-color: transparent !important;
    float: right;
}

    .previous-btn-signup:hover {
        color: var(--mnr-green);
        opacity: .7;
        border-color: var(--mnr-green);
    }

.prev-icon-signup {
    font-size: 18px;
    vertical-align: bottom;
}

.profile-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.profile-tabs {
    flex-grow: 1;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
    .card-login {
        padding: 20px 0px;
    }

        .card-login ul li .nav-link {
            font-size: 16px;
            padding: 7px 0px;
        }

        .card-login ul li {
            width: 100%;
        }

    .general-info-width {
        width: 100% !important;
    }

    .arrow-down-signup-long1 {
        top: 65% !important;
    }

    .arrow-down-signup-long2 {
        top: 70% !important;
    }

    .profile-btn-mob {
        display: flex !important;
        align-items: center;
        align-content: space-around;
        justify-content: space-between;
        flex-direction: row;
        row-gap:5px;
    }

    .profile-inner-btn {
        width: 50% !important;
    }

    .card-login h1 {
        font-size: 18px !important;
        margin-bottom: 10px;
    }

    .general_info_input {
        font-size: 15px;
    }

    .next-btn-signup, .previous-btn-signup {
        padding: 10px;
    }

    .next-btn-signup{
        width:90%;
    }
}
