.guidance-section {
    background-color: var(--mnr-light-grey);
}

.informationHome-row {
    padding: 3rem 0;
}

.informationHome-head {
    font-size: 2.5rem;
    line-height: 3rem;
    font-weight:600;
    padding-bottom: 3rem;
    /*padding-top: 1.5rem;
    margin-bottom: 1rem;*/
    /*letter-spacing: 1px;*/
}

.informationHome-img-checked {
    font-size: 16px;
    font-weight: 400;
    margin: 1rem 0 2rem 4rem;
    text-indent: -2.3em;
    list-style-type: none;
    color: #7a7474;
}

    .informationHome-img-checked img {
        margin-right: 10px;
        height: 26px;
    }

.span-information-text{
    line-height: 2.3rem !important;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
    .span-information-text {
        line-height: 1.3rem !important;
    }
}